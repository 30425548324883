@charset "UTF-8";
@import url(assets/fonts/inter.css);
:root {
  --xa-blue: #3B82F6;
  --xa-light-blue: #72BFF9;
  --xa-green: #9CE931;
  --xa-red: #FC0000;
  --xa-white: #FFFFFF;
  --xa-black: rgb(26, 34, 51);
  --xa-yellow: #EAB308;
  --xa-purple: #B34CB5;
  --xa-orange: #E3843F;
  --xa-meadow: #23C19B;
  --xa-black-dark: #000000;
  --xa-citron: #99A32B;
  --xa-black-medium: #0B0E15;
  --xa-black-light: #102013;
  --xa-neutral-black: #181B1F;
  --xa-separator: #D2D2D2;
  --xa-draft: #C1C3C7;
  --xa-noshow: #FC0000;
  --xa-approve: #B3E573;
  --xa-pending-approval: #F88318;
  --xa-vehicle-detail: #93BCE1;
  --xa-upload-document: #3DB8B0;
  --xa-upload-photo: #C09B80;
  --xa-repair-estimate: #D3CD28;
  --xa-invoice-ready: #B463EE;
  --xa-ready-to-invoice: #93BCE1;
  --xa-bluesh-grey: #201177;
  --xa-ai-analysis: #B463EE;
  --xa-in-progress: #4301FE;
  --xa-not-started: #9CA3AF;
  --xa-dark-gray: rgba(26, 34, 51, 0.7);
  --xa-gray: rgba(26, 34, 51, 0.4);
  --xa-bold-gray: rgba(26, 34, 51, 0.05);
  --xa-mild-bold-gray: rgba(26, 34, 51, 0.03);
  --xa-light-gray: rgba(26, 34, 51, 0.2);
  --xa-light-capsule-white: rgba(255, 255, 255, 0.30);
  --xa-light-radio-gray: rgba(26, 34, 51, 0.02);
  --xa-box-shadow: rgba(96, 108, 128, 0.05);
  --xa-lighter-gray: rgba(26, 34, 51, 0.1);
  --xa-lighter-gray-darker: rgba(26, 34, 51, 0.16);
  --xa-search-border: #D9D9D9;
  --xa-form-border: #D7D7D7;
  --xa-tab-background: #DBDDE2;
  --xa-card-dark: rgba(255, 255, 255, 0.7);
  --xa-tab-default-content: rgba(255, 255, 255, 0.83);
  --xa-action-border: #CDD1D6;
  --xa-primary: #1B03A3;
  --xa-secondary: #4C6BD2;
  --xa-tertiary: #C6CAE7;
  --xa-white-light: rgba(255, 255, 255, 0.47);
  --xa-empty-card: rgba(239, 239, 239, 0.32);
  --xa-border-gray: #C1C1C1;
  --xa-text-primary: #1A2233;
  --xa-text-secondary: #1A223366;
  --xa-text-lighter-gray: #979AA0;
  --xa-legend-highlight: rgba(76, 107, 210, 0.23);
  --xa-icon-shadow-color: #0D10170F;
  --xa-input-icon: #8E8E93;
  --xa-chat-blue: #007AFF;
  --xa-light-background: rgba(227, 229, 244, 1);
  --xa-avatar-border: rgba(30, 30, 30, 0.1);
  --xa-golden-tainoi: #FFCD4B;
  --xa-sunset-orange: #F73D3D;
  --xa-secondary-light: #BDCCFE;
  --xa-tertiary-light: #C6CFED;
  --xa-repair-header: #03A31B;
  --xa-light-blue: #AAC7FA;
  --xa-light-blue1: #69A5C9;
  --xa-light-green: #91C009;
  --xa-light-green1: #7EB699;
  --xa-light-pink: #CE6DD0;
  --xa-dark-red: #B53E3E;
  --xa-dark-red2: #FC0000;
  --xa-green-medium: #83AF4A;
  --xa-orange2: #D2A120;
  --xa-light-gray-disabled: #F4F4F5;
  --xa-dark-gray-disabled: #C8CACE;
  --xa-dp-light-green: #7CD18F;
  --xa-dp-dark-green: #1B9235;
  --xa-dp-medium-green: #09C269;
  --xa-dp-light-orange: #FFDB59;
  --xa-dp-dark-orange: #D4A600;
  --xa-dp-light-gray: #C9C9C9;
  --xa-dp-dark-gray: #78777D;
  --xa-popup-light-gray: #F1F2F6;
  --xa-white-light: rgba(255, 255, 255, 0.15);
  --xa-table-light: #F9F9F9;
  --xa-table-header-light: rgba(198, 202, 231, 0.3);
  --xa-table-header: rgba(236, 236, 236, 1);
  --xa-card-gray: #F8F8F8;
  --xa-booking-out: #DCD6B8;
  --xa-garage-requested: #D4CFFC;
  --xa-lighter-yellow: #ECDBB7;
  --xa-lighter-green: #D2F0AB;
  --xa-booking-out: #B18C6A;
  --xa-assigned-back-review: #1742CE;
  --xa-request-estimate: #FFE2AB;
  --xa-request-invoice: #96AEFF;
  --xa-assigned-back-approval: #88CD30;
  --xa-checked-border: rgba(0, 0, 0, 0.33);
  --xa-checked-background: rgba(157, 157, 157, 0.55);
  --xa-hover-border: rgba(69, 69, 69, 0.55);
  --xa-color-picker-default: #33AECA;
  --xa-input-shadow: #E5E7EB;
  --xa-repair-type-body: #00B5AA;
  --xa-repair-type-body-light: #F0FDF4;
  --xa-repair-type-mechanical: #CE6DD0;
  --xa-repair-type-mechanical-light: #FEF2F2;
  --xa-repair-type-paint: #2B88DE;
  --xa-repair-type-paint-light: #EFF3FF;
  --xa-repair-type-electrical: #8F2BDE;
  --xa-repair-type-electrical-light: #E3BBEB;
  --xa-repair-type-maintenance: #91C009;
  --xa-repair-type-maintenance-light: #C5E4C4;
  --xa-repair-type-tires: #AAC7FA;
  --xa-repair-type-tires-light: #D4E1F8;
  --xa-repair-type-diagnosis: #B53E3E;
  --xa-repair-type-diagnosis-light: #FAE6D0;
  --xa-repair-type-others: #D2A120;
  --xa-repair-type-others-light: #FFF1CF;
  --xa-landing-background: #18094D;
  --xa-landing-text: #fff;
  --xa-landing-blue-background: #00B0F5;
  --xa-landing-black: #000;
  --xa-landing-border-blue: #4412D8;
  --xa-workflow-gray: #7B7B7B;
  --xa-workflow-dark-gray: #54565B;
  --xa-workflow-yellow: #FBDB00;
  --xa-image-card-bg: #F2F2F2;
  --xa-file-wrapper-card: #BAA9A9;
  --xa-reserve-dropdown: #C5C5C5;
  --xa-border-gray2: #1A22331A;
  --xa-destructive-red: #DC2626;
  --xa-claims-table-header: rgba(213, 223, 255, 1);
  --xa-claims-dialog-field-label: #1A2233B2;
  --xa-matrix-table-border: #E6E6E6;
  --xa-condition-background-color: #EAEEFA;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3b82f6);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #3b82f6);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #fc0000);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b82f6;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b82f6;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b82f6;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b82f6;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3b82f6;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3b82f6;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fc0000;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fc0000;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}

.mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3b82f6;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(59, 130, 246, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(59, 130, 246, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #3b82f6;
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(59, 130, 246, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(59, 130, 246, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #fc0000;
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(252, 0, 0, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(252, 0, 0, 0.25);
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #3b82f6);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #3b82f6);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #3b82f6);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(59, 130, 246, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #fc0000);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(59, 130, 246, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(59, 130, 246, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(252, 0, 0, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #3b82f6);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #3b82f6);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(59, 130, 246, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #3b82f6);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(252, 0, 0, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #fc0000);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3b82f6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #3b82f6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(59, 130, 246, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(59, 130, 246, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(252, 0, 0, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(252, 0, 0, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3b82f6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #3b82f6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #3b82f6;
  --mdc-chip-elevated-disabled-container-color: #3b82f6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #3b82f6;
  --mdc-chip-elevated-disabled-container-color: #3b82f6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #fc0000;
  --mdc-chip-elevated-disabled-container-color: #fc0000;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #357af5;
  --mdc-switch-selected-handle-color: #357af5;
  --mdc-switch-selected-hover-state-layer-color: #357af5;
  --mdc-switch-selected-pressed-state-layer-color: #357af5;
  --mdc-switch-selected-focus-handle-color: #1952ef;
  --mdc-switch-selected-hover-handle-color: #1952ef;
  --mdc-switch-selected-pressed-handle-color: #1952ef;
  --mdc-switch-selected-focus-track-color: #76a8f9;
  --mdc-switch-selected-hover-track-color: #76a8f9;
  --mdc-switch-selected-pressed-track-color: #76a8f9;
  --mdc-switch-selected-track-color: #76a8f9;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #357af5;
  --mdc-switch-selected-handle-color: #357af5;
  --mdc-switch-selected-hover-state-layer-color: #357af5;
  --mdc-switch-selected-pressed-state-layer-color: #357af5;
  --mdc-switch-selected-focus-handle-color: #1952ef;
  --mdc-switch-selected-hover-handle-color: #1952ef;
  --mdc-switch-selected-pressed-handle-color: #1952ef;
  --mdc-switch-selected-focus-track-color: #76a8f9;
  --mdc-switch-selected-hover-track-color: #76a8f9;
  --mdc-switch-selected-pressed-track-color: #76a8f9;
  --mdc-switch-selected-track-color: #76a8f9;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fc0000;
  --mdc-switch-selected-handle-color: #fc0000;
  --mdc-switch-selected-hover-state-layer-color: #fc0000;
  --mdc-switch-selected-pressed-state-layer-color: #fc0000;
  --mdc-switch-selected-focus-handle-color: #fa0000;
  --mdc-switch-selected-hover-handle-color: #fa0000;
  --mdc-switch-selected-pressed-handle-color: #fa0000;
  --mdc-switch-selected-focus-track-color: #fd4d4d;
  --mdc-switch-selected-hover-track-color: #fd4d4d;
  --mdc-switch-selected-pressed-track-color: #fd4d4d;
  --mdc-switch-selected-track-color: #fd4d4d;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b82f6;
  --mdc-radio-selected-hover-icon-color: #3b82f6;
  --mdc-radio-selected-icon-color: #3b82f6;
  --mdc-radio-selected-pressed-icon-color: #3b82f6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #3b82f6;
}
.mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b82f6;
  --mdc-radio-selected-hover-icon-color: #3b82f6;
  --mdc-radio-selected-icon-color: #3b82f6;
  --mdc-radio-selected-pressed-icon-color: #3b82f6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #3b82f6;
}
.mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fc0000;
  --mdc-radio-selected-hover-icon-color: #fc0000;
  --mdc-radio-selected-icon-color: #fc0000;
  --mdc-radio-selected-pressed-icon-color: #fc0000;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #fc0000;
}
.mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #3b82f6;
  --mdc-slider-focus-handle-color: #3b82f6;
  --mdc-slider-hover-handle-color: #3b82f6;
  --mdc-slider-active-track-color: #3b82f6;
  --mdc-slider-inactive-track-color: #3b82f6;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3b82f6;
  --mat-mdc-slider-ripple-color: #3b82f6;
  --mat-mdc-slider-hover-ripple-color: rgba(59, 130, 246, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(59, 130, 246, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #3b82f6;
  --mdc-slider-focus-handle-color: #3b82f6;
  --mdc-slider-hover-handle-color: #3b82f6;
  --mdc-slider-active-track-color: #3b82f6;
  --mdc-slider-inactive-track-color: #3b82f6;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3b82f6;
  --mat-mdc-slider-ripple-color: #3b82f6;
  --mat-mdc-slider-hover-ripple-color: rgba(59, 130, 246, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(59, 130, 246, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #fc0000;
  --mdc-slider-focus-handle-color: #fc0000;
  --mdc-slider-hover-handle-color: #fc0000;
  --mdc-slider-active-track-color: #fc0000;
  --mdc-slider-inactive-track-color: #fc0000;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #fc0000;
  --mat-mdc-slider-ripple-color: #fc0000;
  --mat-mdc-slider-hover-ripple-color: rgba(252, 0, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(252, 0, 0, 0.2);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3b82f6);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #3b82f6);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b82f6;
  --mdc-checkbox-selected-hover-icon-color: #3b82f6;
  --mdc-checkbox-selected-icon-color: #3b82f6;
  --mdc-checkbox-selected-pressed-icon-color: #3b82f6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b82f6;
  --mdc-radio-selected-hover-icon-color: #3b82f6;
  --mdc-radio-selected-icon-color: #3b82f6;
  --mdc-radio-selected-pressed-icon-color: #3b82f6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #3b82f6;
}
.mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b82f6;
  --mdc-checkbox-selected-hover-icon-color: #3b82f6;
  --mdc-checkbox-selected-icon-color: #3b82f6;
  --mdc-checkbox-selected-pressed-icon-color: #3b82f6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3b82f6;
  --mdc-radio-selected-hover-icon-color: #3b82f6;
  --mdc-radio-selected-icon-color: #3b82f6;
  --mdc-radio-selected-pressed-icon-color: #3b82f6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #3b82f6;
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #fc0000;
  --mdc-checkbox-selected-hover-icon-color: #fc0000;
  --mdc-checkbox-selected-icon-color: #fc0000;
  --mdc-checkbox-selected-pressed-icon-color: #fc0000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #fc0000;
  --mdc-radio-selected-hover-icon-color: #fc0000;
  --mdc-radio-selected-icon-color: #fc0000;
  --mdc-radio-selected-pressed-icon-color: #fc0000;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #fc0000;
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #3b82f6;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #3b82f6;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: transparent;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #3b82f6;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #3b82f6);
}
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: #3b82f6;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #3b82f6;
}
.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #3b82f6);
}
.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #3b82f6;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fc0000;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #fc0000);
}
.mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #fc0000;
}

.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #3b82f6;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #3b82f6;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #fc0000;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b82f6;
  --mdc-checkbox-selected-hover-icon-color: #3b82f6;
  --mdc-checkbox-selected-icon-color: #3b82f6;
  --mdc-checkbox-selected-pressed-icon-color: #3b82f6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(59, 130, 246, 0.1);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #3b82f6;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3b82f6;
  --mdc-checkbox-selected-hover-icon-color: #3b82f6;
  --mdc-checkbox-selected-icon-color: #3b82f6;
  --mdc-checkbox-selected-pressed-icon-color: #3b82f6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(59, 130, 246, 0.1);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #3b82f6;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #fc0000;
  --mdc-checkbox-selected-hover-icon-color: #fc0000;
  --mdc-checkbox-selected-icon-color: #fc0000;
  --mdc-checkbox-selected-pressed-icon-color: #fc0000;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(252, 0, 0, 0.1);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #fc0000;
}

.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3b82f6;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #3b82f6;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #fc0000;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3b82f6;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #3b82f6;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #fc0000;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3b82f6;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #3b82f6;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #fc0000;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3b82f6;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #3b82f6;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #fc0000;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3b82f6;
  --mat-mdc-button-ripple-color: rgba(59, 130, 246, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #3b82f6;
  --mat-mdc-button-ripple-color: rgba(59, 130, 246, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fc0000;
  --mat-mdc-button-ripple-color: rgba(252, 0, 0, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3b82f6;
  --mat-mdc-button-ripple-color: rgba(59, 130, 246, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #3b82f6;
  --mat-mdc-button-ripple-color: rgba(59, 130, 246, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fc0000;
  --mat-mdc-button-ripple-color: rgba(252, 0, 0, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3b82f6;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #3b82f6;
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #fc0000;
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3b82f6;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #3b82f6;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #fc0000;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #3b82f6;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(59, 130, 246, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #3b82f6;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #3b82f6;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #fc0000;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3b82f6;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #3b82f6;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #fc0000;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(59, 130, 246, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(59, 130, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(59, 130, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3b82f6;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(59, 130, 246, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(59, 130, 246, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(59, 130, 246, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(59, 130, 246, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(59, 130, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(59, 130, 246, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #3b82f6;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(59, 130, 246, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(59, 130, 246, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(59, 130, 246, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(252, 0, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(252, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(252, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #fc0000;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(252, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(252, 0, 0, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(252, 0, 0, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3b82f6;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #3b82f6;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #fc0000;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: #3b82f6;
}
.mat-icon.mat-accent {
  color: #3b82f6;
}
.mat-icon.mat-warn {
  color: #fc0000;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3b82f6;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #3b82f6;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #fc0000;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #fc0000;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #fc0000;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3b82f6;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #3b82f6;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #fc0000;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

/* Font family */
.pt-0 {
  padding-top: 0rem;
}

.mt-0 {
  margin-top: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.p-0 {
  padding-top: 0rem;
  padding-left: 0rem;
  padding-bottom: 0rem;
  padding-right: 0rem;
}

.m-0 {
  margin-top: 0rem;
  margin-left: 0rem;
  margin-bottom: 0rem;
  margin-right: 0rem;
}

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.p-1 {
  padding-top: 0.25rem;
  padding-left: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.25rem;
}

.m-1 {
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.p-2 {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}

.m-2 {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.p-3 {
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 0.75rem;
}

.m-3 {
  margin-top: 0.75rem;
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.p-4 {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.m-4 {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.p-5 {
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  padding-bottom: 1.25rem;
  padding-right: 1.25rem;
}

.m-5 {
  margin-top: 1.25rem;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.pt-auto {
  padding-top: auto;
}

.mt-auto {
  margin-top: auto;
}

.pb-auto {
  padding-bottom: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.pl-auto {
  padding-left: auto;
}

.ml-auto {
  margin-left: auto;
}

.pr-auto {
  padding-right: auto;
}

.mr-auto {
  margin-right: auto;
}

.p-auto {
  padding-top: auto;
  padding-left: auto;
  padding-bottom: auto;
  padding-right: auto;
}

.m-auto {
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 576px) {
  .pt-xs-0 {
    padding-top: 0rem;
  }
  .mt-xs-0 {
    margin-top: 0rem;
  }
}
@media (min-width: 576px) {
  .pb-xs-0 {
    padding-bottom: 0rem;
  }
  .mb-xs-0 {
    margin-bottom: 0rem;
  }
}
@media (min-width: 576px) {
  .pl-xs-0 {
    padding-left: 0rem;
  }
  .ml-xs-0 {
    margin-left: 0rem;
  }
}
@media (min-width: 576px) {
  .pr-xs-0 {
    padding-right: 0rem;
  }
  .mr-xs-0 {
    margin-right: 0rem;
  }
}
@media (min-width: 576px) {
  .p-xs-0 {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
  }
  .m-xs-0 {
    margin-top: 0rem;
    margin-left: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 576px) {
  .px-xs-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .mx-xs-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 576px) {
  .py-xs-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .my-xs-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 576px) {
  .pt-xs-1 {
    padding-top: 0.25rem;
  }
  .mt-xs-1 {
    margin-top: 0.25rem;
  }
}
@media (min-width: 576px) {
  .pb-xs-1 {
    padding-bottom: 0.25rem;
  }
  .mb-xs-1 {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 576px) {
  .pl-xs-1 {
    padding-left: 0.25rem;
  }
  .ml-xs-1 {
    margin-left: 0.25rem;
  }
}
@media (min-width: 576px) {
  .pr-xs-1 {
    padding-right: 0.25rem;
  }
  .mr-xs-1 {
    margin-right: 0.25rem;
  }
}
@media (min-width: 576px) {
  .p-xs-1 {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
  }
  .m-xs-1 {
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 576px) {
  .px-xs-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .mx-xs-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 576px) {
  .py-xs-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .my-xs-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 576px) {
  .pt-xs-2 {
    padding-top: 0.5rem;
  }
  .mt-xs-2 {
    margin-top: 0.5rem;
  }
}
@media (min-width: 576px) {
  .pb-xs-2 {
    padding-bottom: 0.5rem;
  }
  .mb-xs-2 {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 576px) {
  .pl-xs-2 {
    padding-left: 0.5rem;
  }
  .ml-xs-2 {
    margin-left: 0.5rem;
  }
}
@media (min-width: 576px) {
  .pr-xs-2 {
    padding-right: 0.5rem;
  }
  .mr-xs-2 {
    margin-right: 0.5rem;
  }
}
@media (min-width: 576px) {
  .p-xs-2 {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-xs-2 {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 576px) {
  .px-xs-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mx-xs-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 576px) {
  .py-xs-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .my-xs-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 576px) {
  .pt-xs-3 {
    padding-top: 0.75rem;
  }
  .mt-xs-3 {
    margin-top: 0.75rem;
  }
}
@media (min-width: 576px) {
  .pb-xs-3 {
    padding-bottom: 0.75rem;
  }
  .mb-xs-3 {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 576px) {
  .pl-xs-3 {
    padding-left: 0.75rem;
  }
  .ml-xs-3 {
    margin-left: 0.75rem;
  }
}
@media (min-width: 576px) {
  .pr-xs-3 {
    padding-right: 0.75rem;
  }
  .mr-xs-3 {
    margin-right: 0.75rem;
  }
}
@media (min-width: 576px) {
  .p-xs-3 {
    padding-top: 0.75rem;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;
  }
  .m-xs-3 {
    margin-top: 0.75rem;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 576px) {
  .px-xs-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .mx-xs-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 576px) {
  .py-xs-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .my-xs-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 576px) {
  .pt-xs-4 {
    padding-top: 1rem;
  }
  .mt-xs-4 {
    margin-top: 1rem;
  }
}
@media (min-width: 576px) {
  .pb-xs-4 {
    padding-bottom: 1rem;
  }
  .mb-xs-4 {
    margin-bottom: 1rem;
  }
}
@media (min-width: 576px) {
  .pl-xs-4 {
    padding-left: 1rem;
  }
  .ml-xs-4 {
    margin-left: 1rem;
  }
}
@media (min-width: 576px) {
  .pr-xs-4 {
    padding-right: 1rem;
  }
  .mr-xs-4 {
    margin-right: 1rem;
  }
}
@media (min-width: 576px) {
  .p-xs-4 {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  .m-xs-4 {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 576px) {
  .px-xs-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mx-xs-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 576px) {
  .py-xs-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .my-xs-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 576px) {
  .pt-xs-5 {
    padding-top: 1.25rem;
  }
  .mt-xs-5 {
    margin-top: 1.25rem;
  }
}
@media (min-width: 576px) {
  .pb-xs-5 {
    padding-bottom: 1.25rem;
  }
  .mb-xs-5 {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 576px) {
  .pl-xs-5 {
    padding-left: 1.25rem;
  }
  .ml-xs-5 {
    margin-left: 1.25rem;
  }
}
@media (min-width: 576px) {
  .pr-xs-5 {
    padding-right: 1.25rem;
  }
  .mr-xs-5 {
    margin-right: 1.25rem;
  }
}
@media (min-width: 576px) {
  .p-xs-5 {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 1.25rem;
  }
  .m-xs-5 {
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 576px) {
  .px-xs-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .mx-xs-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 576px) {
  .py-xs-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .my-xs-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 576px) {
  .pt-xs-auto {
    padding-top: auto;
  }
  .mt-xs-auto {
    margin-top: auto;
  }
}
@media (min-width: 576px) {
  .pb-xs-auto {
    padding-bottom: auto;
  }
  .mb-xs-auto {
    margin-bottom: auto;
  }
}
@media (min-width: 576px) {
  .pl-xs-auto {
    padding-left: auto;
  }
  .ml-xs-auto {
    margin-left: auto;
  }
}
@media (min-width: 576px) {
  .pr-xs-auto {
    padding-right: auto;
  }
  .mr-xs-auto {
    margin-right: auto;
  }
}
@media (min-width: 576px) {
  .p-xs-auto {
    padding-top: auto;
    padding-left: auto;
    padding-bottom: auto;
    padding-right: auto;
  }
  .m-xs-auto {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
}
@media (min-width: 576px) {
  .px-xs-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 576px) {
  .py-xs-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 768px) {
  .pt-sm-0 {
    padding-top: 0rem;
  }
  .mt-sm-0 {
    margin-top: 0rem;
  }
}
@media (min-width: 768px) {
  .pb-sm-0 {
    padding-bottom: 0rem;
  }
  .mb-sm-0 {
    margin-bottom: 0rem;
  }
}
@media (min-width: 768px) {
  .pl-sm-0 {
    padding-left: 0rem;
  }
  .ml-sm-0 {
    margin-left: 0rem;
  }
}
@media (min-width: 768px) {
  .pr-sm-0 {
    padding-right: 0rem;
  }
  .mr-sm-0 {
    margin-right: 0rem;
  }
}
@media (min-width: 768px) {
  .p-sm-0 {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
  }
  .m-sm-0 {
    margin-top: 0rem;
    margin-left: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 768px) {
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 768px) {
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 768px) {
  .pt-sm-1 {
    padding-top: 0.25rem;
  }
  .mt-sm-1 {
    margin-top: 0.25rem;
  }
}
@media (min-width: 768px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 768px) {
  .pl-sm-1 {
    padding-left: 0.25rem;
  }
  .ml-sm-1 {
    margin-left: 0.25rem;
  }
}
@media (min-width: 768px) {
  .pr-sm-1 {
    padding-right: 0.25rem;
  }
  .mr-sm-1 {
    margin-right: 0.25rem;
  }
}
@media (min-width: 768px) {
  .p-sm-1 {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
  }
  .m-sm-1 {
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 768px) {
  .px-sm-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .mx-sm-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 768px) {
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 768px) {
  .pt-sm-2 {
    padding-top: 0.5rem;
  }
  .mt-sm-2 {
    margin-top: 0.5rem;
  }
}
@media (min-width: 768px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 768px) {
  .pl-sm-2 {
    padding-left: 0.5rem;
  }
  .ml-sm-2 {
    margin-left: 0.5rem;
  }
}
@media (min-width: 768px) {
  .pr-sm-2 {
    padding-right: 0.5rem;
  }
  .mr-sm-2 {
    margin-right: 0.5rem;
  }
}
@media (min-width: 768px) {
  .p-sm-2 {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-sm-2 {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 768px) {
  .px-sm-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mx-sm-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 768px) {
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 768px) {
  .pt-sm-3 {
    padding-top: 0.75rem;
  }
  .mt-sm-3 {
    margin-top: 0.75rem;
  }
}
@media (min-width: 768px) {
  .pb-sm-3 {
    padding-bottom: 0.75rem;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 768px) {
  .pl-sm-3 {
    padding-left: 0.75rem;
  }
  .ml-sm-3 {
    margin-left: 0.75rem;
  }
}
@media (min-width: 768px) {
  .pr-sm-3 {
    padding-right: 0.75rem;
  }
  .mr-sm-3 {
    margin-right: 0.75rem;
  }
}
@media (min-width: 768px) {
  .p-sm-3 {
    padding-top: 0.75rem;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;
  }
  .m-sm-3 {
    margin-top: 0.75rem;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 768px) {
  .px-sm-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .mx-sm-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 768px) {
  .py-sm-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .my-sm-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 768px) {
  .pt-sm-4 {
    padding-top: 1rem;
  }
  .mt-sm-4 {
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  .pb-sm-4 {
    padding-bottom: 1rem;
  }
  .mb-sm-4 {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .pl-sm-4 {
    padding-left: 1rem;
  }
  .ml-sm-4 {
    margin-left: 1rem;
  }
}
@media (min-width: 768px) {
  .pr-sm-4 {
    padding-right: 1rem;
  }
  .mr-sm-4 {
    margin-right: 1rem;
  }
}
@media (min-width: 768px) {
  .p-sm-4 {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  .m-sm-4 {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 768px) {
  .px-sm-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mx-sm-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 768px) {
  .py-sm-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .my-sm-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .pt-sm-5 {
    padding-top: 1.25rem;
  }
  .mt-sm-5 {
    margin-top: 1.25rem;
  }
}
@media (min-width: 768px) {
  .pb-sm-5 {
    padding-bottom: 1.25rem;
  }
  .mb-sm-5 {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 768px) {
  .pl-sm-5 {
    padding-left: 1.25rem;
  }
  .ml-sm-5 {
    margin-left: 1.25rem;
  }
}
@media (min-width: 768px) {
  .pr-sm-5 {
    padding-right: 1.25rem;
  }
  .mr-sm-5 {
    margin-right: 1.25rem;
  }
}
@media (min-width: 768px) {
  .p-sm-5 {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 1.25rem;
  }
  .m-sm-5 {
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 768px) {
  .px-sm-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .mx-sm-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 768px) {
  .py-sm-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .my-sm-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 768px) {
  .pt-sm-auto {
    padding-top: auto;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
}
@media (min-width: 768px) {
  .pb-sm-auto {
    padding-bottom: auto;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
}
@media (min-width: 768px) {
  .pl-sm-auto {
    padding-left: auto;
  }
  .ml-sm-auto {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .pr-sm-auto {
    padding-right: auto;
  }
  .mr-sm-auto {
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .p-sm-auto {
    padding-top: auto;
    padding-left: auto;
    padding-bottom: auto;
    padding-right: auto;
  }
  .m-sm-auto {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .px-sm-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .py-sm-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 992px) {
  .pt-md-0 {
    padding-top: 0rem;
  }
  .mt-md-0 {
    margin-top: 0rem;
  }
}
@media (min-width: 992px) {
  .pb-md-0 {
    padding-bottom: 0rem;
  }
  .mb-md-0 {
    margin-bottom: 0rem;
  }
}
@media (min-width: 992px) {
  .pl-md-0 {
    padding-left: 0rem;
  }
  .ml-md-0 {
    margin-left: 0rem;
  }
}
@media (min-width: 992px) {
  .pr-md-0 {
    padding-right: 0rem;
  }
  .mr-md-0 {
    margin-right: 0rem;
  }
}
@media (min-width: 992px) {
  .p-md-0 {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
  }
  .m-md-0 {
    margin-top: 0rem;
    margin-left: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 992px) {
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 992px) {
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 992px) {
  .pt-md-1 {
    padding-top: 0.25rem;
  }
  .mt-md-1 {
    margin-top: 0.25rem;
  }
}
@media (min-width: 992px) {
  .pb-md-1 {
    padding-bottom: 0.25rem;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 992px) {
  .pl-md-1 {
    padding-left: 0.25rem;
  }
  .ml-md-1 {
    margin-left: 0.25rem;
  }
}
@media (min-width: 992px) {
  .pr-md-1 {
    padding-right: 0.25rem;
  }
  .mr-md-1 {
    margin-right: 0.25rem;
  }
}
@media (min-width: 992px) {
  .p-md-1 {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
  }
  .m-md-1 {
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 992px) {
  .px-md-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .mx-md-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 992px) {
  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 992px) {
  .pt-md-2 {
    padding-top: 0.5rem;
  }
  .mt-md-2 {
    margin-top: 0.5rem;
  }
}
@media (min-width: 992px) {
  .pb-md-2 {
    padding-bottom: 0.5rem;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 992px) {
  .pl-md-2 {
    padding-left: 0.5rem;
  }
  .ml-md-2 {
    margin-left: 0.5rem;
  }
}
@media (min-width: 992px) {
  .pr-md-2 {
    padding-right: 0.5rem;
  }
  .mr-md-2 {
    margin-right: 0.5rem;
  }
}
@media (min-width: 992px) {
  .p-md-2 {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-md-2 {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 992px) {
  .px-md-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mx-md-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 992px) {
  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 992px) {
  .pt-md-3 {
    padding-top: 0.75rem;
  }
  .mt-md-3 {
    margin-top: 0.75rem;
  }
}
@media (min-width: 992px) {
  .pb-md-3 {
    padding-bottom: 0.75rem;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 992px) {
  .pl-md-3 {
    padding-left: 0.75rem;
  }
  .ml-md-3 {
    margin-left: 0.75rem;
  }
}
@media (min-width: 992px) {
  .pr-md-3 {
    padding-right: 0.75rem;
  }
  .mr-md-3 {
    margin-right: 0.75rem;
  }
}
@media (min-width: 992px) {
  .p-md-3 {
    padding-top: 0.75rem;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;
  }
  .m-md-3 {
    margin-top: 0.75rem;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 992px) {
  .px-md-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .mx-md-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 992px) {
  .py-md-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .my-md-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 992px) {
  .pt-md-4 {
    padding-top: 1rem;
  }
  .mt-md-4 {
    margin-top: 1rem;
  }
}
@media (min-width: 992px) {
  .pb-md-4 {
    padding-bottom: 1rem;
  }
  .mb-md-4 {
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .pl-md-4 {
    padding-left: 1rem;
  }
  .ml-md-4 {
    margin-left: 1rem;
  }
}
@media (min-width: 992px) {
  .pr-md-4 {
    padding-right: 1rem;
  }
  .mr-md-4 {
    margin-right: 1rem;
  }
}
@media (min-width: 992px) {
  .p-md-4 {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  .m-md-4 {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 992px) {
  .px-md-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mx-md-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 992px) {
  .py-md-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .my-md-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .pt-md-5 {
    padding-top: 1.25rem;
  }
  .mt-md-5 {
    margin-top: 1.25rem;
  }
}
@media (min-width: 992px) {
  .pb-md-5 {
    padding-bottom: 1.25rem;
  }
  .mb-md-5 {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 992px) {
  .pl-md-5 {
    padding-left: 1.25rem;
  }
  .ml-md-5 {
    margin-left: 1.25rem;
  }
}
@media (min-width: 992px) {
  .pr-md-5 {
    padding-right: 1.25rem;
  }
  .mr-md-5 {
    margin-right: 1.25rem;
  }
}
@media (min-width: 992px) {
  .p-md-5 {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 1.25rem;
  }
  .m-md-5 {
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 992px) {
  .px-md-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .mx-md-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 992px) {
  .py-md-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .my-md-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 992px) {
  .pt-md-auto {
    padding-top: auto;
  }
  .mt-md-auto {
    margin-top: auto;
  }
}
@media (min-width: 992px) {
  .pb-md-auto {
    padding-bottom: auto;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
}
@media (min-width: 992px) {
  .pl-md-auto {
    padding-left: auto;
  }
  .ml-md-auto {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .pr-md-auto {
    padding-right: auto;
  }
  .mr-md-auto {
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .p-md-auto {
    padding-top: auto;
    padding-left: auto;
    padding-bottom: auto;
    padding-right: auto;
  }
  .m-md-auto {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .px-md-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .py-md-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 1200px) {
  .pt-lg-0 {
    padding-top: 0rem;
  }
  .mt-lg-0 {
    margin-top: 0rem;
  }
}
@media (min-width: 1200px) {
  .pb-lg-0 {
    padding-bottom: 0rem;
  }
  .mb-lg-0 {
    margin-bottom: 0rem;
  }
}
@media (min-width: 1200px) {
  .pl-lg-0 {
    padding-left: 0rem;
  }
  .ml-lg-0 {
    margin-left: 0rem;
  }
}
@media (min-width: 1200px) {
  .pr-lg-0 {
    padding-right: 0rem;
  }
  .mr-lg-0 {
    margin-right: 0rem;
  }
}
@media (min-width: 1200px) {
  .p-lg-0 {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
  }
  .m-lg-0 {
    margin-top: 0rem;
    margin-left: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 1200px) {
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 1200px) {
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 1200px) {
  .pt-lg-1 {
    padding-top: 0.25rem;
  }
  .mt-lg-1 {
    margin-top: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .pb-lg-1 {
    padding-bottom: 0.25rem;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .pl-lg-1 {
    padding-left: 0.25rem;
  }
  .ml-lg-1 {
    margin-left: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .pr-lg-1 {
    padding-right: 0.25rem;
  }
  .mr-lg-1 {
    margin-right: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .p-lg-1 {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
  }
  .m-lg-1 {
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .px-lg-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .mx-lg-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .pt-lg-2 {
    padding-top: 0.5rem;
  }
  .mt-lg-2 {
    margin-top: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .pb-lg-2 {
    padding-bottom: 0.5rem;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .pl-lg-2 {
    padding-left: 0.5rem;
  }
  .ml-lg-2 {
    margin-left: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .pr-lg-2 {
    padding-right: 0.5rem;
  }
  .mr-lg-2 {
    margin-right: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .p-lg-2 {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-lg-2 {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .px-lg-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mx-lg-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .pt-lg-3 {
    padding-top: 0.75rem;
  }
  .mt-lg-3 {
    margin-top: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .pb-lg-3 {
    padding-bottom: 0.75rem;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .pl-lg-3 {
    padding-left: 0.75rem;
  }
  .ml-lg-3 {
    margin-left: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .pr-lg-3 {
    padding-right: 0.75rem;
  }
  .mr-lg-3 {
    margin-right: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .p-lg-3 {
    padding-top: 0.75rem;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;
  }
  .m-lg-3 {
    margin-top: 0.75rem;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .px-lg-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .mx-lg-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .py-lg-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .my-lg-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .pt-lg-4 {
    padding-top: 1rem;
  }
  .mt-lg-4 {
    margin-top: 1rem;
  }
}
@media (min-width: 1200px) {
  .pb-lg-4 {
    padding-bottom: 1rem;
  }
  .mb-lg-4 {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1200px) {
  .pl-lg-4 {
    padding-left: 1rem;
  }
  .ml-lg-4 {
    margin-left: 1rem;
  }
}
@media (min-width: 1200px) {
  .pr-lg-4 {
    padding-right: 1rem;
  }
  .mr-lg-4 {
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .p-lg-4 {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  .m-lg-4 {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .px-lg-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mx-lg-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .py-lg-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .my-lg-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 1200px) {
  .pt-lg-5 {
    padding-top: 1.25rem;
  }
  .mt-lg-5 {
    margin-top: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .pb-lg-5 {
    padding-bottom: 1.25rem;
  }
  .mb-lg-5 {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .pl-lg-5 {
    padding-left: 1.25rem;
  }
  .ml-lg-5 {
    margin-left: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .pr-lg-5 {
    padding-right: 1.25rem;
  }
  .mr-lg-5 {
    margin-right: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .p-lg-5 {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 1.25rem;
  }
  .m-lg-5 {
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .px-lg-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .mx-lg-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .py-lg-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .my-lg-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .pt-lg-auto {
    padding-top: auto;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
}
@media (min-width: 1200px) {
  .pb-lg-auto {
    padding-bottom: auto;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
}
@media (min-width: 1200px) {
  .pl-lg-auto {
    padding-left: auto;
  }
  .ml-lg-auto {
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .pr-lg-auto {
    padding-right: auto;
  }
  .mr-lg-auto {
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .p-lg-auto {
    padding-top: auto;
    padding-left: auto;
    padding-bottom: auto;
    padding-right: auto;
  }
  .m-lg-auto {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .px-lg-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .py-lg-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 1400px) {
  .pt-xl-0 {
    padding-top: 0rem;
  }
  .mt-xl-0 {
    margin-top: 0rem;
  }
}
@media (min-width: 1400px) {
  .pb-xl-0 {
    padding-bottom: 0rem;
  }
  .mb-xl-0 {
    margin-bottom: 0rem;
  }
}
@media (min-width: 1400px) {
  .pl-xl-0 {
    padding-left: 0rem;
  }
  .ml-xl-0 {
    margin-left: 0rem;
  }
}
@media (min-width: 1400px) {
  .pr-xl-0 {
    padding-right: 0rem;
  }
  .mr-xl-0 {
    margin-right: 0rem;
  }
}
@media (min-width: 1400px) {
  .p-xl-0 {
    padding-top: 0rem;
    padding-left: 0rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
  }
  .m-xl-0 {
    margin-top: 0rem;
    margin-left: 0rem;
    margin-bottom: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 1400px) {
  .px-xl-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .mx-xl-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
@media (min-width: 1400px) {
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 1400px) {
  .pt-xl-1 {
    padding-top: 0.25rem;
  }
  .mt-xl-1 {
    margin-top: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .pb-xl-1 {
    padding-bottom: 0.25rem;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .pl-xl-1 {
    padding-left: 0.25rem;
  }
  .ml-xl-1 {
    margin-left: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .pr-xl-1 {
    padding-right: 0.25rem;
  }
  .mr-xl-1 {
    margin-right: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .p-xl-1 {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.25rem;
  }
  .m-xl-1 {
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .px-xl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .mx-xl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1400px) {
  .pt-xl-2 {
    padding-top: 0.5rem;
  }
  .mt-xl-2 {
    margin-top: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .pb-xl-2 {
    padding-bottom: 0.5rem;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .pl-xl-2 {
    padding-left: 0.5rem;
  }
  .ml-xl-2 {
    margin-left: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .pr-xl-2 {
    padding-right: 0.5rem;
  }
  .mr-xl-2 {
    margin-right: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .p-xl-2 {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
  .m-xl-2 {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .px-xl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mx-xl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1400px) {
  .pt-xl-3 {
    padding-top: 0.75rem;
  }
  .mt-xl-3 {
    margin-top: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .pb-xl-3 {
    padding-bottom: 0.75rem;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .pl-xl-3 {
    padding-left: 0.75rem;
  }
  .ml-xl-3 {
    margin-left: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .pr-xl-3 {
    padding-right: 0.75rem;
  }
  .mr-xl-3 {
    margin-right: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .p-xl-3 {
    padding-top: 0.75rem;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;
  }
  .m-xl-3 {
    margin-top: 0.75rem;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .px-xl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .mx-xl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .py-xl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .my-xl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 1400px) {
  .pt-xl-4 {
    padding-top: 1rem;
  }
  .mt-xl-4 {
    margin-top: 1rem;
  }
}
@media (min-width: 1400px) {
  .pb-xl-4 {
    padding-bottom: 1rem;
  }
  .mb-xl-4 {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1400px) {
  .pl-xl-4 {
    padding-left: 1rem;
  }
  .ml-xl-4 {
    margin-left: 1rem;
  }
}
@media (min-width: 1400px) {
  .pr-xl-4 {
    padding-right: 1rem;
  }
  .mr-xl-4 {
    margin-right: 1rem;
  }
}
@media (min-width: 1400px) {
  .p-xl-4 {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  .m-xl-4 {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 1400px) {
  .px-xl-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mx-xl-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (min-width: 1400px) {
  .py-xl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .my-xl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 1400px) {
  .pt-xl-5 {
    padding-top: 1.25rem;
  }
  .mt-xl-5 {
    margin-top: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .pb-xl-5 {
    padding-bottom: 1.25rem;
  }
  .mb-xl-5 {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .pl-xl-5 {
    padding-left: 1.25rem;
  }
  .ml-xl-5 {
    margin-left: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .pr-xl-5 {
    padding-right: 1.25rem;
  }
  .mr-xl-5 {
    margin-right: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .p-xl-5 {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 1.25rem;
  }
  .m-xl-5 {
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .px-xl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .mx-xl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .py-xl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .my-xl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .pt-xl-auto {
    padding-top: auto;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
}
@media (min-width: 1400px) {
  .pb-xl-auto {
    padding-bottom: auto;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
}
@media (min-width: 1400px) {
  .pl-xl-auto {
    padding-left: auto;
  }
  .ml-xl-auto {
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  .pr-xl-auto {
    padding-right: auto;
  }
  .mr-xl-auto {
    margin-right: auto;
  }
}
@media (min-width: 1400px) {
  .p-xl-auto {
    padding-top: auto;
    padding-left: auto;
    padding-bottom: auto;
    padding-right: auto;
  }
  .m-xl-auto {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
}
@media (min-width: 1400px) {
  .px-xl-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1400px) {
  .py-xl-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
chr-booking-journal {
  position: relative;
}

.dx-widget {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.dx-scheduler-header {
  background-color: var(--xa-white);
  border: none;
  height: 50px;
}

.dx-template-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dx-scheduler-work-space {
  border: none;
}

.dx-scheduler-appointment {
  background-color: transparent;
  box-shadow: none;
}

.dx-toolbar .dx-toolbar-item {
  display: flex;
  align-items: center;
}
.dx-toolbar .dx-toolbar-items-container {
  display: flex;
  flex-direction: row;
  align-items: self-start;
}
.dx-toolbar .dx-toolbar-before {
  position: relative;
  display: flex;
}
.dx-toolbar .dx-toolbar-after {
  position: relative;
  display: flex;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected) {
  border: none;
}

.dx-button-has-text .dx-button-content {
  padding: 8px 0;
}

.dx-scheduler-header .dx-toolbar .dx-scheduler-navigator .dx-scheduler-navigator-caption {
  border: none;
  min-width: 0;
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected):hover {
  background-color: var(--xa-tertiary);
}

.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected {
  background-color: var(--xa-primary);
  color: var(--xa-white);
}
.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected:hover {
  background-color: var(--xa-tertiary);
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover,
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused,
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
  background-color: transparent;
  color: inherit;
}

.dx-scheduler-appointment-content {
  padding: 0;
}

.dx-scheduler-work-space-day .dx-scheduler-appointment-reduced .dx-scheduler-appointment-content,
.dx-scheduler-work-space-week .dx-scheduler-appointment-reduced .dx-scheduler-appointment-content,
.dx-scheduler-work-space-work-week .dx-scheduler-appointment-reduced .dx-scheduler-appointment-content {
  padding-right: 5px;
}

.dx-scheduler-appointment-reduced .dx-scheduler-appointment-content {
  padding-right: 5px;
}

.dx-toolbar-after .dx-buttongroup-item.dx-button.dx-button-mode-contained {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 18px;
  gap: 10px;
  width: 79px;
  height: 30px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: rgba(26, 34, 51, 0.2);
  filter: drop-shadow(0px 1px 3px rgba(96, 108, 128, 0.05));
}
.dx-toolbar-after .dx-buttongroup-item.dx-button.dx-button-mode-contained:first-child {
  border-radius: 8px 0px 0px 8px;
}
.dx-toolbar-after .dx-buttongroup-item.dx-button.dx-button-mode-contained:last-child {
  border-radius: 0px 8px 8px 0px;
}

.dx-scheduler-appointment.dx-state-hover.dx-resizable {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-scheduler-all-day-appointment,
.dx-scheduler-timeline .dx-scheduler-appointment,
.dx-scheduler-work-space-month .dx-scheduler-appointment {
  box-shadow: none;
}

.dx-scheduler-all-day-appointment.dx-state-hover.dx-resizable,
.dx-scheduler-timeline .dx-scheduler-appointment.dx-state-hover.dx-resizable,
.dx-scheduler-work-space-month .dx-scheduler-appointment.dx-state-hover.dx-resizable {
  box-shadow: none;
}

.dx-scheduler-appointment .dx-scheduler-appointment:not(.dx-scheduler-appointment-drag-source)::before,
.dx-scheduler-appointment.dx-state-focused:not(.dx-scheduler-appointment-drag-source)::before {
  background-color: transparent;
}

.dx-scheduler-all-day-panel .dx-scheduler-all-day-table-cell {
  border-bottom: 1px solid #DADCE0;
}

.dx-scheduler-all-day-table-cell,
.dx-scheduler-date-table-cell {
  border-top: 1px solid #DADCE0;
  border-left: 1px solid #DADCE0;
}

.dx-scheduler-date-table-cell,
.dx-scheduler-header-panel-cell {
  border-right: 1px solid #DADCE0;
}

.dx-scheduler-date-table-cell,
.dx-scheduler-time-panel-cell {
  height: 40px;
}

.dx-scheduler-header-panel-cell {
  height: 30px;
}

.dx-scheduler-header-panel {
  margin-top: 0px;
}

.dx-scheduler-all-day-title,
.dx-scheduler-all-day-table {
  min-height: 75px;
}

#booking-journal-filter chq-button button {
  height: 30px !important;
}

.dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
  height: 100px;
}

.dx-scheduler-appointment-horizontal {
  height: 40px;
}
.dx-scheduler-appointment-horizontal .template-wrap {
  padding: 5px 15px;
}
.dx-scheduler-appointment-horizontal .template-wrap .template-content-wrap {
  display: flex;
}
.dx-scheduler-appointment-horizontal .template-wrap div {
  padding: 0;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  display: flex;
}
.dx-scheduler-appointment-horizontal .template-wrap div.subject {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
.dx-scheduler-appointment-horizontal .template-wrap div.subject mat-icon {
  position: relative;
}
.dx-scheduler-appointment-horizontal .template-wrap div.booking-detail {
  flex-direction: column;
}
.dx-scheduler-appointment-horizontal .template-wrap .context-menu {
  right: 0;
}

.dx-scheduler-appointment-reduced .template-wrap .context-menu {
  right: -10px;
}

.outer-wrapper {
  border-radius: 5px;
  border: 2px solid transparent;
}

.template-color {
  display: flex;
  flex: 1 1 20px;
}

.template-wrap {
  display: flex;
  flex: 1 1 calc(100% - 20px);
  height: 100%;
  padding: 0px 18px 0px 4px;
}
.template-wrap:hover {
  box-shadow: #6200ee;
}
.template-wrap .template-content-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.template-wrap.black div {
  color: var(--xa-black);
}
.template-wrap div {
  padding: 2px 0;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  color: var(--xa-white);
  text-overflow: ellipsis;
  display: flex;
}
.template-wrap div.subject {
  font-weight: 600;
  font-size: 12px;
}
.template-wrap div.subject mat-icon {
  height: 12px;
  position: absolute;
  right: 0;
}
.template-wrap div.type {
  font-weight: 500;
  font-size: 10px;
}
.template-wrap div.type mat-icon {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}
.template-wrap div.status {
  font-weight: 700;
  font-size: 12px;
}
.template-wrap .context-menu {
  position: absolute;
  right: -15px;
  bottom: -4px;
}
.template-wrap .context-menu .mat-mdc-menu-trigger {
  background: transparent;
}
.template-wrap .context-menu .mat-mdc-menu-trigger .mat-icon {
  color: #FFFFFF;
}
.template-wrap.tooltip {
  padding: 10px;
}
.template-wrap.tooltip .booking-detail {
  text-align: left;
}
.template-wrap.tooltip div.subject mat-icon {
  right: 20px;
}
.template-wrap.tooltip .context-menu {
  position: relative;
  float: right;
}

.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-overlay-content,
.dx-scheduler-overlay-panel .dx-overlay-content {
  border-radius: 10px;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-scrollview-content {
  background-color: var(--xa-popup-light-gray);
  padding: 15px;
  padding-top: 40px;
  border-radius: 10px;
  max-height: 200px;
}
.dx-scheduler-appointment-tooltip-wrapper .dx-scrollview-content:before {
  content: "More bookings";
  text-align: center;
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 14px;
  font-weight: 600;
}

.tooltip-template-wrap {
  display: flex;
  height: 100%;
  padding: 7px 10px 7px 10px;
  border-radius: 10px;
  background-color: var(--xa-white);
}
.tooltip-template-wrap .tooltip-content-wrap {
  height: 100%;
  width: 100%;
}
.tooltip-template-wrap div {
  padding: 2px 0;
  overflow: hidden;
  font-weight: 500;
  font-size: 12px;
  text-overflow: ellipsis;
  text-align: left;
}
.tooltip-template-wrap div.subject {
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
}
.tooltip-template-wrap .context-menu {
  display: flex;
  align-items: center;
}
.tooltip-template-wrap .context-menu .mat-mdc-menu-trigger {
  background: transparent;
}

.mat-mdc-menu-content {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  align-items: center;
  width: 120px;
  padding: 0 !important;
}
.mat-mdc-menu-content .mat-mdc-menu-item {
  display: flex;
  flex-direction: row;
  gap: 0;
  min-height: 40px;
}
.mat-mdc-menu-content .mat-mdc-menu-item mat-icon {
  font-size: 14px;
  margin-right: 10px;
}
.mat-mdc-menu-content .mat-mdc-menu-item mat-icon .mdc-list-item__primary-text span {
  font-family: "Inter";
  font-size: 14px;
}
.mat-mdc-menu-content .separator {
  width: 125px;
  height: 0px;
  border: 1px solid rgba(26, 34, 51, 0.1);
}
.mat-mdc-menu-content .separator-100 {
  width: 100%;
  height: 0px;
  border: 1px solid rgba(26, 34, 51, 0.1);
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: var(--xa-secondary);
  --mdc-slider-focus-handle-color: var(--xa-secondary);
  --mdc-slider-hover-handle-color: var(--xa-secondary);
  --mdc-slider-active-track-color: var(--xa-secondary);
  --mdc-slider-inactive-track-color: var(--xa-secondary);
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--xa-secondary);
  --mat-mdc-slider-ripple-color: var(--xa-secondary);
  --mat-mdc-slider-hover-ripple-color: rgba(59, 130, 246, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(59, 130, 246, 0.2);
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: var(--primary-secondary, var(--xa-primary, #6200ee)) !important;
  border-color: var(--xa-primary, var(--xa-primary, #6200ee)) !important;
}

.mat-mdc-slider .mdc-slider__tick-mark--active {
  background: transparent !important;
}

.dx-scheduler-header-row .dx-template-wrapper {
  margin: 10px 0;
  margin: 5px;
  border-radius: 10px;
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header {
  margin: 5px 0 0 0 !important;
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info {
  display: flex;
  justify-content: center;
  gap: 0.2vw;
  flex-wrap: wrap;
}
@media (max-width: 1250px) {
  .dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info {
    flex-direction: column;
    display: block;
    font-size: 0.8rem;
  }
  .dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info span {
    position: relative;
    align-self: center;
    display: block;
  }
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info.flex-column {
  flex-direction: column;
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info.flex-column span {
  position: relative;
  align-self: center;
  text-wrap: wrap;
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info span {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 6px;
  gap: 0.2vw;
  border-radius: 3px;
  justify-content: center;
  word-break: break-all;
  white-space: break-spaces;
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info span.has-background {
  background-color: var(--xa-lighter-gray);
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header.header-info span span {
  background: black;
  color: white;
  line-height: 14px;
}
.dx-scheduler-header-row .dx-template-wrapper .calendar-header.date-info {
  font-weight: 500;
  line-height: 17px;
  color: var(--xa-primary);
}

.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
  background: var(--xa-secondary);
}
.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell:before {
  content: none;
}
.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell .disable-date {
  background-image: none;
}
.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell .calendar-header.header-info span {
  background-color: var(--xa-white);
  color: var(--xa-black-medium);
  align-items: center;
}
.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell .calendar-header.header-info span.has-background {
  background-color: var(--xa-white);
}
.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell .calendar-header.date-info {
  color: var(--xa-white);
}

.dx-scheduler-date-time-indicator::before {
  z-index: 450;
}

/*disabling date */
@-moz-document url-prefix() {
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
    position: relative;
  }
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .disable-date {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.disable-date,
.past-date,
.weekend,
.dinner,
.lunch-time {
  height: 100%;
  width: 100%;
}

.disable-date,
.past-date,
.weekend {
  justify-content: start;
}

.weekend {
  background-color: rgba(252, 0, 0, 0.1);
  background-image: repeating-linear-gradient(135deg, rgba(181, 1, 1, 0.3), rgba(181, 1, 1, 0.3) 2px, transparent 4px, transparent 9px);
}

.past-date {
  background: rgba(26, 34, 51, 0.1);
}

.disable-date {
  background: rgba(26, 34, 51, 0.1);
}

.dx-scheduler-header-panel-cell .disable-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dinner {
  background: #FFF9C2;
}
.dinner:after {
  content: "Lunch break";
  text-align: center;
}

.dx-scheduler-time-panel-cell .dinner {
  color: rgb(255, 193, 7);
  font-weight: 400;
  background: transparent;
}
.dx-scheduler-time-panel-cell .dinner:after {
  content: none;
}

.dx-draggable {
  cursor: auto;
}

td.dx-scheduler-time-panel-cell:not(.dx-scheduler-header-panel-current-time-cell) .dinner .cafe {
  height: 200%;
  width: 100%;
  left: 50%;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position-y: 50%;
  -webkit-mask-position-x: 100%;
  margin-top: -4px;
  background-color: #ffc107;
}

.booking-calendar .dx-scheduler-date-table-cell {
  padding: 0;
  opacity: 1;
}
.booking-calendar .dx-scheduler-date-table-cell:hover {
  background-color: var(--xa-empty-card);
  background-image: url("../../assets/icons/plus-blue.svg");
  background-repeat: no-repeat;
  background-position: center;
}

@media all and (-ms-high-contrast: none) {
  td.dx-scheduler-time-panel-cell .dinner .cafe {
    background-color: transparent;
  }
}
.dx-draggable-source {
  opacity: 0.5;
}

.dx-draggable-dragging > * {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.2);
}

.dx-draggable-clone .dx-draggable {
  border: none;
}
.dx-draggable-clone .draft-wrap .draggable {
  cursor: grabbing;
}
.dx-draggable-clone .draft-wrap .draft-content-wrap .heading .draft-action-menu {
  visibility: hidden;
}

.draft-container {
  height: 100vh;
  overflow: hidden;
}
.draft-container .header {
  padding: 10px;
  margin-bottom: 0 !important;
}
.draft-container .header .action button .mat-icon {
  height: 16px;
  width: 16px;
}
.draft-container .dx-scrollable {
  height: calc(100vh - 112px);
}
.draft-container .dx-card {
  border-radius: 0;
  border: none;
}

.draft-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 5px;
  background-color: var(--xa-white);
  border: 1px solid #E0E3E6;
  border-radius: 16px;
  gap: 10px;
}
.draft-wrap .draggable {
  align-self: center;
  cursor: grab;
}
.draft-wrap .draggable:active {
  cursor: grabbing;
}
.draft-wrap .draggable.disable-drag {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}
.draft-wrap .draft-content-wrap {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 12px;
  width: 340px;
  gap: 10px;
}
.draft-wrap .draft-content-wrap .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.draft-wrap .draft-content-wrap .heading .subject {
  font-weight: 600;
  font-size: 16px;
  color: var(--xa-black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 120px;
}
.draft-wrap .draft-content-wrap .heading .status-pill {
  display: flex;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 999px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  flex: 1 1 90px;
}
.draft-wrap .draft-content-wrap .heading .draft-action-menu {
  display: flex;
  gap: 5px;
}
.draft-wrap .draft-content-wrap .heading .draft-action-menu button {
  background: none;
  padding: 0;
}
.draft-wrap .draft-content-wrap .heading .draft-action-menu a {
  display: flex;
  border: 1px solid #D1D5DB;
  padding: 6px 12px 6px 12px;
  border-radius: 8px;
  align-items: center;
  gap: 6px;
}
.draft-wrap .draft-content-wrap .heading .draft-action-menu a .mat-icon {
  height: 18px;
  width: 18px;
}
.draft-wrap .draft-content-wrap .content {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #464D59;
  gap: 5px;
}
.draft-wrap .draft-content-wrap .content .content-row {
  display: flex;
  gap: 5px;
}
.draft-wrap .draft-content-wrap .content .content-row .content-column {
  display: flex;
  flex: 1 1 50%;
}
.draft-wrap .draft-content-wrap .content .vehicle-location {
  font-weight: 500;
  color: var(--xa-primary);
}
.draft-wrap .draft-content-wrap .content .no-show {
  font-weight: 500;
  color: var(--xa-noshow);
}
.draft-wrap .draft-content-wrap .content .value {
  color: var(--xa-black);
}
.draft-wrap .draft-content-wrap .badge {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  font-variation-settings: "slnt" -10;
  color: rgba(26, 34, 51, 0.4);
}

.employee-calendar .dx-scheduler-header,
.employee-calendar .dx-scheduler-header-panel-container,
.employee-calendar .dx-scheduler-time-panel {
  background-color: #f4f5f8;
}
.employee-calendar .dx-scheduler-navigator .dx-buttongroup-wrapper div,
.employee-calendar .dx-scheduler-navigator .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal {
  background-color: #f4f5f8;
}
.employee-calendar .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell .calendar-header.date-info {
  font-weight: bold;
  color: var(--xa-primary);
}
.employee-calendar .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
  background: transparent;
}
.employee-calendar .dx-toolbar .dx-toolbar-after .dx-buttongroup-item.dx-button.dx-button-mode-contained:last-child {
  border-radius: 8px;
  display: none;
}
.employee-calendar .dx-scheduler-header-panel-cell {
  border-left: 0px;
  border-right: 0px;
}
.employee-calendar .dx-scheduler-header-panel-empty-cell {
  border-bottom: 0px;
  border-right: 0px;
}
.employee-calendar .dx-scheduler-header-tables-container {
  border-top: 2px solid rgba(221, 221, 221, 0.6);
}
.employee-calendar .template-wrap {
  padding-right: 24px;
}
.employee-calendar .template-wrap div.subject mat-icon {
  right: 5px;
}

.dx-popup-content {
  max-height: 340px !important;
}

.dx-scheduler-appointment {
  border: none;
}

.dx-scheduler-all-day-appointment,
.dx-scheduler-timeline .dx-scheduler-appointment,
.dx-scheduler-work-space-month .dx-scheduler-appointment {
  box-shadow: none;
}

.dx-scheduler-all-day-appointment.dx-state-hover.dx-resizable,
.dx-scheduler-timeline .dx-scheduler-appointment.dx-state-hover.dx-resizable,
.dx-scheduler-work-space-month .dx-scheduler-appointment.dx-state-hover.dx-resizable {
  box-shadow: none;
}

.dx-scheduler-work-space-day .dx-scheduler-all-day-appointment .dx-scheduler-appointment-content,
.dx-scheduler-work-space-week .dx-scheduler-all-day-appointment .dx-scheduler-appointment-content,
.dx-scheduler-work-space-work-week .dx-scheduler-all-day-appointment .dx-scheduler-appointment-content,
.dx-scheduler-work-space-month .dx-scheduler-appointment-content {
  padding: 0;
}

.hovered {
  border: 2px solid #FF4E4E;
}

.cdk-drag-preview .draggable {
  cursor: grabbing !important;
}
.cdk-drag-preview .draft-content-wrap .heading .draft-action-menu {
  visibility: hidden;
}

.technician-calendar {
  margin-bottom: calc(85px + 1vw);
}
.technician-calendar dx-scheduler {
  border: 1px solid #D2D2D2;
  border-radius: 10px 10px 0 0;
}
.technician-calendar.technician-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;
  border: 1px solid #CECFD0;
  border-radius: 8px;
  margin-bottom: 0;
}
.technician-calendar.technician-view .header-shadow {
  height: 62px;
  background: #F7F9FD;
  border-radius: 8px 8px 0 0;
  margin: 0 -10px;
}
.technician-calendar.technician-view dx-scheduler {
  border: none;
  border-radius: 0;
}
.technician-calendar.technician-view .dx-scheduler-header {
  height: 50px;
}
.technician-calendar.technician-view .dx-toolbar .dx-toolbar-items-container {
  background: #D5DFFE;
  border-radius: 16px 16px 0 0;
}
.technician-calendar.technician-view .dx-scheduler-date-table-cell {
  height: 30px;
}
.technician-calendar.technician-view .dx-scheduler-time-panel {
  width: 50px;
}
.technician-calendar.technician-view .dx-scheduler-time-panel-cell {
  height: 30px;
  border: 1px solid #dadce0;
  padding-right: 5px;
}
.technician-calendar.technician-view .dx-scheduler-time-panel-cell:first-of-type, .technician-calendar.technician-view .dx-scheduler-time-panel-cell:last-of-type {
  border-top: none;
}
.technician-calendar.technician-view .dx-scheduler-time-panel-cell .dx-template-wrapper {
  margin-top: 0px;
}
.technician-calendar.technician-view .dx-scheduler-date-time-indicator {
  margin-left: 50px;
}
.technician-calendar.technician-view .outer-wrapper {
  margin-right: 15px;
}
.technician-calendar .disabled {
  pointer-events: none;
  opacity: 0.4;
}
.technician-calendar .lunch-time,
.technician-calendar .unavailable-time {
  height: 100%;
}
.technician-calendar .dx-scheduler-header-panel-empty-cell,
.technician-calendar .dx-scheduler-header-panel {
  margin: 0;
}
.technician-calendar .dx-scheduler-header {
  height: 62px;
}
.technician-calendar .dx-scheduler-container,
.technician-calendar .dx-scheduler-header {
  background: #F7F9FD;
  border-radius: 10px 10px 0 0;
}
.technician-calendar .dx-scheduler-header-panel-cell {
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: var(--xa-primary);
}
.technician-calendar .dx-scheduler-header-panel-week-cell {
  border: 1px solid #c4c4c4;
  border-top: none;
}
.technician-calendar .dx-scheduler-header-panel-container {
  background: #F7F9FD;
  border-top: 1px solid #c4c4c4;
}
.technician-calendar .dx-scheduler-group-flex-container .dx-scheduler-group-row .dx-scheduler-group-header {
  width: 250px;
  height: 50px;
  align-items: center;
  padding: 0;
}
.technician-calendar .dx-scheduler-group-flex-container .dx-scheduler-group-row .dx-scheduler-group-header:has(.empty-slot) {
  border-top: none;
}
.technician-calendar .dx-scheduler-group-flex-container .dx-scheduler-group-row .dx-scheduler-group-header .outer-wrapper {
  height: 100%;
  border: none;
}
.technician-calendar .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
  background: none;
}
.technician-calendar .dx-scheduler-date-table-cell {
  height: 50px;
}
.technician-calendar.team-view .dx-scheduler-group-flex-container .dx-scheduler-group-row .dx-scheduler-group-header {
  height: 150px;
}
.technician-calendar.team-view .dx-scheduler-date-table-cell {
  height: 150px;
}
.technician-calendar .resource-wrapper {
  display: flex;
  gap: 5px;
  width: 250px;
  padding: 5px;
  align-items: center;
  cursor: pointer;
}
.technician-calendar .resource-wrapper.empty-slot {
  cursor: auto;
}
.technician-calendar .resource-wrapper.not-selected {
  opacity: 0.6;
}
.technician-calendar .resource-wrapper.selected {
  opacity: 1;
  background-color: #D5DFFE;
}
.technician-calendar .resource-wrapper * {
  display: flex;
}
.technician-calendar .resource-wrapper .avatar {
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  border: 2px solid #AE9CF6;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  overflow: hidden;
  background: var(--xa-card-gray);
  font-size: 14px;
  font-weight: 600;
}
.technician-calendar .resource-wrapper .avatar mat-icon,
.technician-calendar .resource-wrapper .avatar img {
  width: 100%;
}
.technician-calendar .resource-wrapper .detail {
  flex-direction: column;
  font-size: 12px;
  gap: 5px;
  flex: 1;
}
.technician-calendar .resource-wrapper .detail .top {
  justify-content: space-between;
}
.technician-calendar .resource-wrapper .detail .top .name-wrapper {
  gap: 10px;
}
.technician-calendar .resource-wrapper .detail .top .name {
  font-size: 12px;
  font-weight: 600;
  max-width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.technician-calendar .resource-wrapper .detail .top .name.team {
  max-width: 140px;
}
.technician-calendar .resource-wrapper .detail .top .name.small {
  max-width: 135px;
}
.technician-calendar .resource-wrapper .detail .top .date {
  font-size: 10px;
  font-weight: 400;
  gap: 5px;
}
.technician-calendar .resource-wrapper .detail .top .date mat-icon {
  height: 10px;
  width: 10px;
}
.technician-calendar .resource-wrapper .detail .top .employees {
  margin-left: 7px;
}
.technician-calendar .resource-wrapper .detail .top .employees .avatar {
  font-size: 12px;
  background-color: var(--xa-white);
  height: 20px;
  width: 20px;
  margin-left: -7px;
}
.technician-calendar .resource-wrapper .detail .top .employees .avatar.plus-icon mat-icon {
  fill: var(--xa-gray) !important;
}
.technician-calendar .resource-wrapper .detail .middle {
  justify-content: space-between;
  align-items: center;
}
.technician-calendar .resource-wrapper .detail .middle .role {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #9CA3AF;
}
.technician-calendar .resource-wrapper .detail .middle .service {
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0.01em;
  gap: 5px;
}
.technician-calendar .resource-wrapper .detail .middle .service mat-icon {
  height: 10px;
  width: 10px;
  fill: #00B5AA !important;
}
.technician-calendar .resource-wrapper .detail .middle .service.body {
  background: var(--xa-repair-type-body-light);
  color: var(--xa-repair-type-body);
}
.technician-calendar .resource-wrapper .detail .middle .service.mechanical {
  background: var(--xa-repair-type-mechanical-light);
  color: var(--xa-repair-type-mechanical);
}
.technician-calendar .resource-wrapper .detail .middle .service.paint {
  background: var(--xa-repair-type-paint-light);
  color: var(--xa-repair-type-paint);
}
.technician-calendar .resource-wrapper .detail .bottom {
  justify-content: space-between;
}
.technician-calendar .resource-wrapper .detail .bottom .competencies {
  flex-wrap: wrap;
}
.technician-calendar .resource-wrapper .detail .bottom .job {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  color: #6B7280;
}
.technician-calendar .resource-wrapper .detail .bottom .work {
  align-items: center;
  gap: 5px;
  background: var(--xa-input-shadow);
  padding: 2px 8px;
  border-radius: 20px;
  color: var(--xa-repair-header);
  font-size: 9px;
  font-weight: 400;
}
.technician-calendar .resource-wrapper .detail .bottom .work mat-icon {
  height: 10px;
  width: 10px;
  fill: var(--xa-repair-header) !important;
}
.technician-calendar .resource-wrapper .detail .bottom .progress {
  width: 100px;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  font-weight: 500;
  color: var(--xa-primary);
}
.technician-calendar .resource-wrapper .detail .bottom .progress .mdc-linear-progress {
  height: 6px;
  border-radius: 60px;
}
.technician-calendar .resource-wrapper .detail .bottom .progress .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--xa-input-shadow);
}
.technician-calendar .resource-wrapper .detail .bottom .progress .mdc-linear-progress__bar-inner {
  border: 3px solid var(--xa-primary);
  background: var(--xa-primary);
  border-radius: 60px;
}
.technician-calendar .resource-wrapper .detail .bottom .progress .mdc-linear-progress__secondary-bar {
  display: none;
}
.technician-calendar .dx-scheduler-timeline .dx-scheduler-date-table-cell,
.technician-calendar .dx-scheduler-timeline .dx-scheduler-header-panel-cell:not(.dx-scheduler-header-panel-week-cell) {
  width: 50px;
}
.technician-calendar .dx-scheduler-appointment {
  border-radius: 8px;
  background: transparent !important;
  padding: 1px;
}
.technician-calendar .dx-template-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.technician-calendar .dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected) {
  background-color: transparent;
}
.technician-calendar .tooltip-button .dx-button {
  padding: 6px 12px 6px 12px;
  gap: 10px;
  border-radius: 22px;
  border: 1px solid #D1D5DB;
}
.technician-calendar .tooltip-button .dx-button .dx-button-content {
  padding: 0;
}
.technician-calendar .tooltip-button .dx-button .dx-button-content .dx-icon-filter {
  font-size: 12px;
}
.technician-calendar .next-prev-widget .dx-button {
  background: none;
  border: none;
}
.technician-calendar .next-prev-widget .dx-texteditor.dx-editor-outlined {
  background: none;
  border: none;
}
.technician-calendar .next-prev-widget .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.technician-calendar .dx-toolbar-before .dx-toolbar-item-content {
  padding: 5px;
}
.technician-calendar .dx-toolbar-after .dx-buttongroup-wrapper {
  background-color: #E5E7EB;
  border-radius: 20px;
}
.technician-calendar .dx-toolbar-after .dx-buttongroup-item.dx-button.dx-button-mode-contained {
  border-radius: 20px;
  border: none;
}
.technician-calendar .dx-scheduler-header .dx-toolbar .dx-scheduler-navigator .dx-scheduler-navigator-caption {
  font-size: 16px;
  font-weight: 500;
}
.technician-calendar .outer-wrapper {
  border: none;
  border-radius: 8px;
}
.technician-calendar .dx-scheduler-work-space-flex-container .dx-scrollable-content {
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(221, 221, 221, 0.6);
}
.technician-calendar .even-day {
  background-color: var(--xa-mild-bold-gray);
}

.technician-calendar-repair-type {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 8px;
  border-radius: 20px;
}
.technician-calendar-repair-type mat-icon.type-icon {
  height: 15px;
  width: 15px;
}
.technician-calendar-repair-type.body {
  background-color: var(--xa-repair-type-body-light);
  color: var(--xa-repair-type-body);
}
.technician-calendar-repair-type.body mat-icon.type-icon {
  fill: var(--xa-repair-type-body) !important;
}
.technician-calendar-repair-type.body mat-icon.type-icon svg {
  fill: var(--xa-repair-type-body) !important;
}
.technician-calendar-repair-type.body mat-icon.type-icon path {
  fill: var(--xa-repair-type-body) !important;
}
.technician-calendar-repair-type.mechanical {
  background-color: var(--xa-repair-type-mechanical-light);
  color: var(--xa-repair-type-mechanical);
}
.technician-calendar-repair-type.mechanical mat-icon.type-icon {
  fill: var(--xa-repair-type-mechanical) !important;
}
.technician-calendar-repair-type.mechanical mat-icon.type-icon svg {
  fill: var(--xa-repair-type-mechanical) !important;
}
.technician-calendar-repair-type.mechanical mat-icon.type-icon path {
  fill: var(--xa-repair-type-mechanical) !important;
}
.technician-calendar-repair-type.paint {
  background-color: var(--xa-repair-type-paint-light);
  color: var(--xa-repair-type-paint);
}
.technician-calendar-repair-type.paint mat-icon.type-icon {
  fill: var(--xa-repair-type-paint) !important;
}
.technician-calendar-repair-type.paint mat-icon.type-icon svg {
  fill: var(--xa-repair-type-paint) !important;
}
.technician-calendar-repair-type.paint mat-icon.type-icon path {
  fill: var(--xa-repair-type-paint) !important;
}
.technician-calendar-repair-type.electrical {
  background-color: var(--xa-repair-type-electrical-light);
  color: var(--xa-repair-type-electrical);
}
.technician-calendar-repair-type.electrical mat-icon.type-icon {
  fill: var(--xa-repair-type-electrical) !important;
}
.technician-calendar-repair-type.electrical mat-icon.type-icon svg {
  fill: var(--xa-repair-type-electrical) !important;
}
.technician-calendar-repair-type.electrical mat-icon.type-icon path {
  fill: var(--xa-repair-type-electrical) !important;
}
.technician-calendar-repair-type.maintenance {
  background-color: var(--xa-repair-type-maintenance-light);
  color: var(--xa-repair-type-maintenance);
}
.technician-calendar-repair-type.maintenance mat-icon.type-icon {
  fill: var(--xa-repair-type-maintenance) !important;
}
.technician-calendar-repair-type.maintenance mat-icon.type-icon svg {
  fill: var(--xa-repair-type-maintenance) !important;
}
.technician-calendar-repair-type.maintenance mat-icon.type-icon path {
  fill: var(--xa-repair-type-maintenance) !important;
}
.technician-calendar-repair-type.tires, .technician-calendar-repair-type.tyres {
  background-color: var(--xa-repair-type-tires-light);
  color: var(--xa-repair-type-tires);
}
.technician-calendar-repair-type.tires mat-icon.type-icon, .technician-calendar-repair-type.tyres mat-icon.type-icon {
  fill: var(--xa-repair-type-tires) !important;
}
.technician-calendar-repair-type.tires mat-icon.type-icon svg, .technician-calendar-repair-type.tyres mat-icon.type-icon svg {
  fill: var(--xa-repair-type-tires) !important;
}
.technician-calendar-repair-type.tires mat-icon.type-icon path, .technician-calendar-repair-type.tyres mat-icon.type-icon path {
  fill: var(--xa-repair-type-tires) !important;
}
.technician-calendar-repair-type.diagnosis {
  background-color: var(--xa-repair-type-diagnosis-light);
  color: var(--xa-repair-type-diagnosis);
}
.technician-calendar-repair-type.diagnosis mat-icon.type-icon {
  fill: var(--xa-repair-type-diagnosis) !important;
}
.technician-calendar-repair-type.diagnosis mat-icon.type-icon svg {
  fill: var(--xa-repair-type-diagnosis) !important;
}
.technician-calendar-repair-type.diagnosis mat-icon.type-icon path {
  fill: var(--xa-repair-type-diagnosis) !important;
}
.technician-calendar-repair-type.others {
  background-color: var(--xa-repair-type-others-light);
  color: var(--xa-repair-type-others);
}
.technician-calendar-repair-type.others mat-icon.type-icon {
  fill: var(--xa-repair-type-others) !important;
}
.technician-calendar-repair-type.others mat-icon.type-icon svg {
  fill: var(--xa-repair-type-others) !important;
}
.technician-calendar-repair-type.others mat-icon.type-icon path {
  fill: var(--xa-repair-type-others) !important;
}
.technician-calendar-repair-type.transparent {
  background-color: transparent;
}
.technician-calendar-repair-type.disabled {
  background-color: var(--xa-empty-card);
  color: var(--xa-dark-gray);
}
.technician-calendar-repair-type.disabled mat-icon.type-icon {
  fill: var(--xa-dark-gray) !important;
}
.technician-calendar-repair-type.disabled mat-icon.type-icon svg {
  fill: var(--xa-dark-gray) !important;
}
.technician-calendar-repair-type.disabled mat-icon.type-icon path {
  fill: var(--xa-dark-gray) !important;
}
.technician-calendar-repair-type.show-more {
  font-weight: 700;
  background-color: var(--xa-repair-type-paint-light);
  color: var(--xa-primary);
}
.technician-calendar-repair-type.show-more mat-icon.type-icon {
  fill: var(--xa-primary) !important;
}
.technician-calendar-repair-type.show-more mat-icon.type-icon svg {
  fill: var(--xa-primary) !important;
}
.technician-calendar-repair-type.show-more mat-icon.type-icon path {
  fill: var(--xa-primary) !important;
}

.repair-detail-sidenav {
  box-shadow: none;
  background-color: var(--xa-input-shadow);
}

.competencies {
  display: flex;
  gap: 3px;
}

.technician-calendar-mat-drawer-container {
  background-color: transparent;
}
.technician-calendar-mat-drawer-container.filter-mat-drawer-container {
  z-index: 510 !important;
}
.technician-calendar-mat-drawer-container .mat-drawer-backdrop {
  position: fixed;
}
.technician-calendar-mat-drawer-container .mat-sidenav {
  position: fixed;
}
.technician-calendar-mat-drawer-container .mat-sidenav.filter-sidenav {
  width: 400px;
  background-color: #F1F2F6;
}
.technician-calendar-mat-drawer-container .mat-sidenav.filter-sidenav .sidenav-container {
  padding: calc(15px + 0.8vw);
  padding-bottom: calc(55px + 0.8vw);
}
.technician-calendar-mat-drawer-container .mat-sidenav.filter-sidenav .checkbox-mark {
  width: 20px !important;
}
.technician-calendar-mat-drawer-container .mat-sidenav.repair-detail-sidenav {
  width: 350px;
  background: transparent;
  box-shadow: none;
  margin-bottom: 175px;
  margin-top: 75px;
}
.technician-calendar-mat-drawer-container .mat-sidenav.repair-detail-sidenav .sidenav-container {
  padding-right: calc(15px + 0.8vw);
}
.technician-calendar-mat-drawer-container .mat-sidenav .mat-drawer-inner-container {
  overflow-x: hidden;
}
.technician-calendar-mat-drawer-container .mat-sidenav .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .header .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .header .action button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  background: var(--xa-black);
  border-radius: 50%;
  height: 36px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .header .action button .mat-icon {
  height: 16px;
  width: 16px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .form-container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 15px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .form-container label.control-label {
  text-transform: uppercase;
  line-height: 30px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .form-container xa-radio-group {
  display: flex;
  gap: 10px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .form-container .checkbox-with-background {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.technician-calendar-mat-drawer-container .mat-sidenav .form-container .checkbox-with-background xa-checkbox-item {
  width: calc(50% - 25px);
  background: var(--xa-input-shadow);
  border-radius: 20px;
  padding: 0 10px;
}
.technician-calendar-mat-drawer-container .mat-sidenav .footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 400px;
  background-color: #F1F2F6;
  z-index: 500;
  height: 68px;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.dx-popup-normal:has(.competency-tooltip) {
  border-radius: 8px;
}
.dx-popup-normal:has(.competency-tooltip) .dx-popup-content {
  padding: 10px;
}
.dx-popup-normal:has(.competency-tooltip) .dx-template-wrapper {
  gap: 5px;
}

.dx-popup-normal:has(.employee-tooltip) .dx-popover-arrow::after {
  background-color: var(--xa-secondary-light);
}
.dx-popup-normal:has(.employee-tooltip) .dx-popup-content {
  background-color: var(--xa-secondary-light);
  max-height: max-content !important;
}

.employee-tooltip {
  display: flex;
  gap: 5px;
  margin: 0 -10px 10px -10px;
}
.employee-tooltip img,
.employee-tooltip .profile {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border-color: black;
  border: 1px solid black;
  font-size: 9px;
  padding: 1px;
}

.technician-calendar-resource-search-field button.xa-button[appearancetype=outline-fab] {
  padding: 5px !important;
}
.technician-calendar-resource-search-field button.xa-button[appearancetype=outline-fab] mat-icon {
  height: 24px;
  width: 24px;
  fill: var(--xa-primary) !important;
}

.technician-calendar-slot-wrap {
  border-radius: 5px;
  padding: 6px 2px;
  font-size: 10px;
  justify-content: start;
  height: 100%;
}
.technician-calendar-slot-wrap.preview {
  padding: 5px;
}
.technician-calendar-slot-wrap .slot-content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  height: 100%;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .team {
  display: flex;
  align-items: center;
  gap: 5px;
  width: calc(100% - 35px);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .team .avatar {
  font-size: 9px;
  width: 17px;
  height: 17px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--xa-card-gray);
  border: 2px solid #AE9CF6;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .team .avatar .profile {
  color: var(--xa-black);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .team .avatar img {
  height: 20px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .team .link-to-repair {
  text-decoration: inherit;
  color: var(--xa-white);
  text-overflow: ellipsis;
  overflow: hidden;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .team .link-to-repair:hover {
  color: var(--xa-primary);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .employees {
  display: flex;
  margin-left: 7px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .employees .avatar {
  height: 20px;
  width: 20px;
  margin-left: -7px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--xa-card-gray);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .employees .avatar .profile {
  color: var(--xa-black);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .employees .avatar img {
  height: 20px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status-wrapper {
  display: flex;
  align-items: center;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status-wrapper a {
  display: flex;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .bm-timer-action {
  display: flex;
  gap: 3px;
  margin-right: 3px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .bm-timer-action mat-icon {
  height: 16px;
  width: 16px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  gap: 5px;
  color: var(--xa-white);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.ongoing {
  background-color: var(--xa-red);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.ongoing mat-icon {
  border-radius: 50%;
  animation: glow 1s infinite alternate;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.notstarted {
  background-color: var(--xa-not-started);
  padding: 0 8px 0 2px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.notstarted mat-icon {
  height: 20px;
  width: 20px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.readytostart {
  background-color: var(--xa-draft);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.paused {
  background-color: var(--xa-pending-approval);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.completed {
  padding: 2px 8px 2px 4px;
  background-color: var(--xa-approve);
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status.completed mat-icon {
  width: 15px;
  height: 15px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .status mat-icon {
  width: 10px;
  height: 10px;
}
.technician-calendar-slot-wrap .slot-content-wrap .subject .unassign-btn {
  width: 15px;
  height: 15px;
}
.technician-calendar-slot-wrap .slot-content-wrap .type {
  display: flex;
  align-items: end;
  justify-content: space-between;
  font-size: 9px;
  font-weight: 400;
  color: var(--xa-white);
}
.technician-calendar-slot-wrap .slot-content-wrap .type .progress {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.technician-calendar-slot-wrap .slot-content-wrap .type .progress.repair-orders {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.technician-calendar-slot-wrap .slot-content-wrap .type .progress .preview-mode-vehicle-details {
  overflow: hidden;
  text-overflow: ellipsis;
}
.technician-calendar-slot-wrap .slot-content-wrap .type .percentage {
  font-weight: 700;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject {
  flex-direction: column;
  height: 100%;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .team {
  width: 100%;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .status-wrapper {
  justify-content: center;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .bm-timer-action {
  display: none;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .status {
  padding: 2px;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .status .status-text {
  display: none;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .status.notstarted {
  padding: 1px;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .status.notstarted mat-icon {
  height: 16px;
  width: 16px;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .status.completed {
  padding: 4px;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .status.completed mat-icon {
  width: 10px;
  height: 10px;
}
.technician-calendar-slot-wrap.xs:not(.preview) .slot-content-wrap .subject .unassign-btn {
  display: none;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .subject .bm-timer-action {
  display: none;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .subject .status {
  padding: 2px;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .subject .status .status-text {
  display: none;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .subject .status.notstarted {
  padding: 1px;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .subject .status.notstarted mat-icon {
  height: 16px;
  width: 16px;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .subject .status.completed {
  padding: 4px;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .subject .status.completed mat-icon {
  width: 10px;
  height: 10px;
}
.technician-calendar-slot-wrap.sm:not(.preview) .slot-content-wrap .repair-orders {
  display: none;
}
.technician-calendar-slot-wrap.md:not(.preview) .slot-content-wrap .subject .bm-timer-action {
  display: none;
}
.technician-calendar-slot-wrap.md:not(.preview) .slot-content-wrap .subject .status {
  padding: 2px;
}
.technician-calendar-slot-wrap.md:not(.preview) .slot-content-wrap .subject .status .status-text {
  display: none;
}
.technician-calendar-slot-wrap.md:not(.preview) .slot-content-wrap .subject .status.notstarted {
  padding: 1px;
}
.technician-calendar-slot-wrap.md:not(.preview) .slot-content-wrap .subject .status.notstarted mat-icon {
  height: 16px;
  width: 16px;
}
.technician-calendar-slot-wrap.md:not(.preview) .slot-content-wrap .subject .status.completed {
  padding: 4px;
}
.technician-calendar-slot-wrap.md:not(.preview) .slot-content-wrap .subject .status.completed mat-icon {
  width: 10px;
  height: 10px;
}
.technician-calendar-slot-wrap.lg:not(.preview) .slot-content-wrap .subject .status {
  padding: 2px;
}
.technician-calendar-slot-wrap.lg:not(.preview) .slot-content-wrap .subject .status .status-text {
  display: none;
}
.technician-calendar-slot-wrap.lg:not(.preview) .slot-content-wrap .subject .status.notstarted {
  padding: 1px;
}
.technician-calendar-slot-wrap.lg:not(.preview) .slot-content-wrap .subject .status.notstarted mat-icon {
  height: 16px;
  width: 16px;
}
.technician-calendar-slot-wrap.lg:not(.preview) .slot-content-wrap .subject .status.completed {
  padding: 4px;
}
.technician-calendar-slot-wrap.lg:not(.preview) .slot-content-wrap .subject .status.completed mat-icon {
  width: 10px;
  height: 10px;
}
.technician-calendar-slot-wrap.xl:not(.preview) .slot-content-wrap .subject .team {
  width: calc(100% - 105px);
}
.technician-calendar-slot-wrap.technician-view-slot-wrap .slot-content-wrap .subject {
  align-items: start;
  font-size: 18px;
  font-weight: 600;
  flex-wrap: wrap;
  text-wrap: wrap;
  gap: 5px;
}
.technician-calendar-slot-wrap.technician-view-slot-wrap .slot-content-wrap .subject .vehicle-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.technician-calendar-slot-wrap.technician-view-slot-wrap .slot-content-wrap .subject .vehicle-info span {
  display: flex;
}
.technician-calendar-slot-wrap.technician-view-slot-wrap .slot-content-wrap .subject .vehicle-info span .border-left {
  height: 12px;
  display: flex;
  margin-top: 6px;
  border: none;
  width: 2px;
  background: #d1d5db;
  margin-right: 5px;
}
.technician-calendar-slot-wrap.technician-view-slot-wrap .slot-content-wrap .type {
  font-size: 12px;
  font-weight: 700;
  flex-wrap: wrap;
  text-wrap: wrap;
  gap: 5px;
}
.technician-calendar-slot-wrap.technician-view-slot-wrap .slot-content-wrap .type .job-status {
  font-size: 14px;
  font-weight: 400;
  color: #E5E7EB;
}
.technician-calendar-slot-wrap.technician-view-slot-wrap .slot-content-wrap .type .percentage {
  font-size: 14px;
  font-weight: 700;
  color: #E5E7EB;
}

.slot-hovered {
  border: 3px solid #EAE10F !important;
}

.slot-dimmed .technician-calendar-slot-wrap {
  opacity: 0.3;
}

.slot-active-slot {
  border: 3px solid var(--xa-primary) !important;
}

.technician-calendar-holiday-wrap {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #C5E3C1;
  height: 100%;
  border-radius: 5px;
}

@media only screen and (max-width: 1070px) {
  .hide-small {
    display: none;
  }
}
@media only screen and (max-width: 975px) {
  .hide-small,
  .tooltip-button .dx-button-text {
    display: none;
  }
  .technician-calendar .tooltip-button .dx-button .dx-button-content .dx-icon {
    margin: 0;
  }
  .dx-scheduler-header .dx-toolbar .dx-toolbar-item-content {
    padding: 0;
  }
}
@media only screen and (max-width: 975px) {
  .technician-calendar .dx-toolbar .dx-toolbar-before,
  .technician-calendar .dx-toolbar .dx-toolbar-after,
  .technician-calendar .dx-scheduler-header .dx-toolbar .dx-toolbar-item-content {
    padding: 0;
  }
}
@media only screen and (max-width: 790px) {
  .dx-button-has-icon .dx-button-content {
    padding: 0;
  }
}
.dx-scheduler-appointment-tooltip-wrapper {
  z-index: 900 !important;
}

.dx-scheduler-appointment-tooltip-wrapper:has(.technician-calendar-slot-wrap) .dx-scrollview-content,
.dx-scheduler-appointment-tooltip-wrapper:has(.technician-calendar-holiday-wrap) .dx-scrollview-content {
  background-color: var(--xa-white);
  padding: 5px;
}
.dx-scheduler-appointment-tooltip-wrapper:has(.technician-calendar-slot-wrap) .dx-scrollview-content:before,
.dx-scheduler-appointment-tooltip-wrapper:has(.technician-calendar-holiday-wrap) .dx-scrollview-content:before {
  display: none;
}

.technician-calendar-footer .loader,
.repair-detail-container .loader,
.technician-calendar .loader {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-width: 2px;
  display: inline-block;
}

.technician-calendar .loader {
  width: 15px;
  height: 15px;
}

@keyframes glow {
  from {
    box-shadow: 0 0 3px -3px var(--xa-white);
  }
  to {
    box-shadow: 0 0 3px 3px var(--xa-white);
  }
}
.operators {
  display: flex;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 500;
  gap: 5px;
  flex-wrap: wrap;
}
.operators .operator {
  display: flex;
  align-items: center;
  gap: 2px;
}
.operators .operator .circle {
  width: 10px;
  height: 10px;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
}

.technician-calendar-preview-tooltip-task-list-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 5px;
  overflow: auto;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-header {
  display: flex;
  flex: 1;
  font-weight: 700;
  line-height: 24px;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-list {
  display: flex;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-wrap: wrap;
  padding-bottom: 5px;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-list:not(:last-child) {
  border-bottom: 1px solid var(--xa-separator);
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-list .task-name {
  color: #687182;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-list .task-status .checked {
  display: flex;
  border: 1px solid var(--xa-repair-header);
  background-color: var(--xa-repair-header);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-list .task-status .checked::after {
  content: "";
  width: 5px;
  height: 10px;
  border: solid var(--xa-white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-left: 7px;
  margin-top: 3px;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-list .task-status .circle {
  display: flex;
  border: 1px solid var(--xa-light-gray);
  background-color: var(--xa-light-radio-gray);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-name {
  width: 200px;
  text-align: left;
}
.technician-calendar-preview-tooltip-task-list-wrapper .task-category,
.technician-calendar-preview-tooltip-task-list-wrapper .task-status {
  display: flex;
  width: 75px;
  text-align: left;
  align-items: center;
  justify-content: center;
}

.technician-calendar-preview-action {
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 10px 0;
}
.technician-calendar-preview-action button.xa-button[size=sm]:not([appearancetype*=fab]) {
  min-width: 0;
  border: 1px solid rgba(26, 34, 51, 0.2);
  box-shadow: 0px 1px 3px 0px rgba(96, 108, 128, 0.0509803922);
}
.technician-calendar-preview-action.ongoing .action-text {
  display: none;
}

html,
body {
  height: 100%;
  background: var(--xa-popup-light-gray);
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

body:has(.mat-drawer-container-has-open:not(.draft-sidenav-container, .repair-detail-mat-drawer-container)) {
  overflow: hidden !important;
}

div[hidden] {
  display: none !important;
}

input,
textarea {
  font-family: "Inter", sans-serif;
}

input.otp-input {
  outline: none;
  border-radius: 8px !important;
  border: 1px solid #AFB1B6 !important;
  font-size: 14px !important;
  color: #131415;
  height: 40px !important;
}

input.otp-input:disabled {
  color: #131415 !important;
  background: white !important;
}

input.otp-input.border-black {
  border: 1px solid #131415 !important;
}

input.otp-input.border-danger {
  border: 1px solid #FC0000 !important;
}

input.otp-input.border-success {
  border: 1px solid #64CE77 !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 700 !important;
}

h1 {
  font-size: 30px;
  line-height: 40px;
}

h2 {
  font-size: 26px;
  line-height: 35px;
}

h3 {
  font-size: 20px;
  line-height: 25px;
}

h4 {
  font-size: 16px;
  line-height: 20px;
}

h5 {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}

span.required {
  color: red;
}

/*color css */
.text-black {
  color: var(--xa-black) !important;
}

.text-black-medium {
  color: var(--xa-black-medium) !important;
}

.text-white {
  color: var(--xa-white) !important;
}

.text-danger {
  color: var(--xa-red) !important;
}

.text-success {
  color: var(--xa-green) !important;
}

.text-primary {
  color: var(--xa-blue) !important;
}

.text-gray {
  color: var(--xa-gray) !important;
}

.text-dark-gray {
  color: var(--xa-dark-gray) !important;
}

.text-light-gray {
  color: var(--xa-light-gray) !important;
}

.text-dark-gray-disabled {
  color: var(--xa-dark-gray-disabled) !important;
}

.text-dark-red2 {
  color: var(--xa-dark-red2) !important;
}

.text-green-medium {
  color: var(--xa-green-medium) !important;
}

.bg-black {
  background-color: var(--xa-black);
}

.bg-white {
  background-color: var(--xa-white);
}

.bg-danger {
  background-color: var(--xa-red);
}

.bg-success {
  background-color: var(--xa-green);
}

.bg-primary {
  background-color: var(--xa-blue);
}

.bg-gray {
  background-color: var(--xa-gray);
}

.bg-dark-gray {
  background-color: var(--xa-dark-gray);
}

.bg-light-blue {
  background-color: var(--xa-light-blue);
}

.bg-light-green {
  background-color: var(--xa-light-green);
}

.bg-light-pink {
  background-color: var(--xa-light-pink);
}

.bg-dark-red {
  background-color: var(--xa-dark-red);
}

.bg-orange2 {
  background-color: var(--xa-orange2);
}

.bg-tab-default-content {
  background-color: var(--xa-tab-default-content);
}

.bg-light-gray-disabled {
  background-color: var(--xa-light-gray-disabled);
}

.bg-dp-light-green {
  background-color: var(--xa-dp-light-green);
}

.bg-dp-dark-green {
  background-color: var(--xa-dp-dark-green);
}

.bg-dp-light-orange {
  background-color: var(--xa-dp-light-orange);
}

.bg-dp-dark-orange {
  background-color: var(--xa-dp-dark-orange);
}

.bg-dp-light-gray {
  background-color: var(--xa-dp-light-gray);
}

.bg-dp-dark-gray {
  background-color: var(--xa-dp-dark-gray);
}

.border-black {
  border: 1px solid var(--xa-black);
}

.border-danger {
  border: 1px solid var(--xa-red);
}

.border-success {
  border: 1px solid var(--xa-green);
}

.border-primary {
  border: 1px solid var(--xa-blue);
}

.border-gray {
  border: 1px solid var(--xa-gray);
}

.border-dark-gray {
  border: 1px solid var(--xa-dark-gray);
}

.border-light-gray {
  border: 1px solid var(--xa-light-gray);
}

.border-light-gray-galaxy {
  border: 1px solid var(--xa-gray);
}

.dx-scrollable-scrollbar {
  background: #f1f1f1 !important;
}
.dx-scrollable-scrollbar .dx-scrollable-scroll {
  opacity: 1 !important;
  background-color: #c1c1c1 !important;
  display: block !important;
}

@media (pointer: coarse) {
  .dx-scrollable-scrollbar {
    display: none;
  }
}
/*button css */
button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px 12px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 20px;
}
button label {
  font-size: 16px;
  line-height: 20px;
}

.btn-primary {
  background-color: var(--xa-primary);
  color: var(--xa-white);
}

.btn-danger {
  background-color: var(--xa-red);
  color: var(--xa-white);
}

a.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.btn-disabled,
.btn-primary[disabled],
.btn-danger[disabled] {
  background-color: var(--xa-primary);
  color: var(--xa-white);
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.btn-outline {
  background-color: var(--xa-white);
  color: var(--xa-black);
  border: 1px solid var(--xa-light-gray);
}

.btn-outline-danger {
  background-color: var(--xa-white);
  color: var(--xa-red);
  border: 1px solid var(--xa-red);
}

.btn-outline.disabled,
.btn-outline[disabled],
.btn-outline-danger[disabled],
.btn-outline-danger.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.btn-outline-disabled,
.btn-outline[disabled],
.btn-outline-danger[disabled] {
  background-color: var(--xa-white);
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.btn-text {
  background-color: var(--xa-white);
  color: var(--xa-black);
}

.btn-text-danger {
  background-color: var(--xa-white);
  color: var(--xa-red);
}

.btn-text-disabled,
.btn-text[disabled],
.btn-text-danger[disabled] {
  background-color: var(--xa-white);
  color: var(--xa-light-gray);
  cursor: default;
}

.mat-icon {
  height: 20px;
  width: 20px;
  fill: var(--xa-black) !important;
  cursor: pointer;
}
.mat-icon[size=sm] {
  height: 18px !important;
  width: 18px !important;
}
.mat-icon[color=none] {
  fill: none !important;
}
.mat-icon[color=primary] {
  fill: var(--xa-primary) !important;
}
.mat-icon[color=secondary] {
  fill: var(--xa-secondary) !important;
}
.mat-icon[color=tertiary] {
  fill: var(--xa-tertiary) !important;
}
.mat-icon[color=white], .mat-icon[ng-reflect-color=white] {
  fill: var(--xa-white) !important;
  color: var(--xa-white);
}
.mat-icon[color=white] svg path, .mat-icon[ng-reflect-color=white] svg path {
  fill: var(--xa-white) !important;
}
.mat-icon[color=red] {
  fill: var(--xa-red) !important;
}
.mat-icon[color=black-medium] {
  fill: var(--xa-black-medium) !important;
  fill-opacity: 0.4;
}
.mat-icon[color=black] {
  fill: var(--xa-black) !important;
  color: var(--xa-black);
}
.mat-icon[color=gray] {
  fill: var(--xa-gray);
  color: var(--xa-gray);
}
.mat-icon[color=dark-gray] {
  fill: var(--xa-dark-gray) !important;
  color: var(--xa-dark-gray);
}
.mat-icon[color=dark-gray] svg path {
  fill: var(--xa-dark-gray) !important;
}
.mat-icon[color=light-gray] {
  fill: var(--xa-light-gray);
  color: var(--xa-light-gray);
}
.mat-icon[color=light-gray] svg path {
  fill: var(--xa-light-gray);
}
.mat-icon[color=dark-gray-disabled] {
  fill: var(--xa-dark-gray-disabled) !important;
  color: var(--xa-dark-gray-disabled);
}
.mat-icon[color=dark-gray-disabled] svg path {
  fill: var(--xa-dark-gray-disabled);
}
.mat-icon[color=green-medium], .mat-icon[ng-reflect-color=green-medium] {
  fill: var(--xa-green-medium);
  color: var(--xa-green-medium);
}
.mat-icon[color=green-medium] svg path, .mat-icon[ng-reflect-color=green-medium] svg path {
  fill: var(--xa-green-medium);
}
.mat-icon[color=green-light] {
  fill: var(--xa-light-green) !important;
}
.mat-icon[color=dark-green] {
  fill: var(--xa-dp-dark-green) !important;
}
.mat-icon[color=dark-green] svg path {
  fill: var(--xa-dp-dark-green);
}
.mat-icon[color=golden-tainoi] {
  fill: var(--xa-golden-tainoi) !important;
}
.mat-icon[color=golden-tainoi] svg path {
  fill: var(--xa-golden-tainoi);
}
.mat-icon[color=dark-red2], .mat-icon[ng-reflect-color=dark-red2] {
  fill: var(--xa-dark-red2);
  color: var(--xa-dark-red2);
}
.mat-icon[color=dark-red2] svg path, .mat-icon[ng-reflect-color=dark-red2] svg path {
  fill: var(--xa-dark-red2);
}
.mat-icon[color=light-blue1], .mat-icon.mat-light-blue1 {
  fill: var(--xa-light-blue1);
  color: var(--xa-light-blue1);
}
.mat-icon[color=light-blue1] svg path, .mat-icon.mat-light-blue1 svg path {
  fill: var(--xa-light-blue1);
}
.mat-icon[color=light-green1], .mat-icon.mat-light-green1 {
  fill: var(--xa-light-green1);
  color: var(--xa-light-green1);
}
.mat-icon[color=light-green1] svg path, .mat-icon.mat-light-green1 svg path {
  fill: var(--xa-light-green1);
}
.mat-icon[color=circle-yellow] circle {
  fill: var(--xa-orange2);
  color: var(--xa-orange2);
}

.mat-ripple:not(.xa-button),
.mdc-checkbox__ripple {
  display: none !important;
}

.mdc-checkbox__background {
  border-radius: 4px !important;
}

.text-primary.mat-icon {
  fill: var(--xa-blue) !important;
}

.d-none {
  display: none !important;
}

.exportSVG {
  display: none !important;
}

.apexcharts-menu-open {
  min-width: 150px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.apexcharts-menu-open .icon-wrapper {
  display: flex;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
}
.apexcharts-menu-open .icon-wrapper .material-icons {
  font-size: 20px !important;
}

.mat-drawer-container {
  background-color: var(--xa-white);
  color: var(--xa-black);
}

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.row .col-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 25%;
  max-width: 25%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-28 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 28%;
  max-width: 28%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-27 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 29.66667%;
  max-width: 29.66667%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-19 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 11%;
  max-width: 11%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-16 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 16%;
  max-width: 16%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-35 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 33.333337%;
  max-width: 33.333337%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col {
  box-sizing: border-box;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 8.33333337%;
  max-width: 8.33333337%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-1_5 {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 12.44444%;
  max-width: 12.44444%;
}
.row .col-15 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 10%;
  max-width: 10%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-24 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 20%;
  max-width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-25 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 23.333333%;
  max-width: 23.333333%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-2_1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-4_1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 66.66666666%;
  max-width: 66.66666666%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 75%;
  max-width: 75%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 83.333333%;
  max-width: 83.333333%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 41.67%;
  max-width: 41.67%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 58.33%;
  max-width: 58.33%;
  padding-left: 10px;
  padding-right: 10px;
}
.row .col-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 850px) {
  /* … */
  .row {
    margin-left: -6px;
    margin-right: -6px;
  }
  .row .col-3 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-28 {
    flex-basis: 25%;
    max-width: 25%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-19 {
    flex-basis: 25%;
    max-width: 25%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-27 {
    flex-basis: 26%;
    max-width: 26%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-19 {
    flex-basis: 22%;
    max-width: 22%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-35 {
    flex-basis: 28%;
    max-width: 28%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col {
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-15 {
    flex-basis: 20%;
    max-width: 20%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-25 {
    flex-basis: 24%;
    max-width: 24%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-1 {
    flex-basis: 12.666667%;
    max-width: 12.6666667%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-1_5 {
    flex-basis: 18.999999%;
    max-width: 18.999999%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-2 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-2_1 {
    flex-basis: 22%;
    max-width: 22%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-4_1 {
    flex-basis: 28%;
    max-width: 28%;
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-4 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-8 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-12 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .row .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.http-toast.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--xa-red) !important;
}

.http-info-toast.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--xa-blue) !important;
}

.whatsapp-notification.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--xa-white) !important;
  max-width: 100%;
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid var(--xa-separator);
}

.confirmation-popup-backdrop {
  filter: blur(1px);
  background-color: rgba(26, 34, 51, 0.9);
}

.action-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 16px;
  align-items: center;
}
.action-container span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}
.action-container .time-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 8px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.action-container .prop {
  font-weight: 600;
}
.action-container .btn-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #3A00E5;
}
.action-container .cancel-btn {
  margin-right: 40px;
}
.action-container .btn-ios {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  color: #333333;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 8px;
}
.action-container .btn-danger {
  margin-right: 10px;
  color: #FC0000;
}
.action-container .btn-ok {
  padding-right: 0px;
}

@media only screen and (max-device-width: 1280px) and (orientation: landscape) {
  .mat-datepicker-popup .mat-datepicker-content {
    overflow: scroll;
  }
  .mat-datepicker-popup .mat-datepicker-content .mat-calendar {
    width: 250px;
  }
}
.footer-full-wrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 101px;
  z-index: 500;
  background: var(--xa-white);
  justify-content: space-between;
  height: 50px;
  padding: 5px 0;
  box-shadow: 0 0px 1px 0px inset;
}
.footer-full-wrapper .footer-image-wrapper {
  display: flex;
}
.footer-full-wrapper .footer-image-wrapper .image-wrapper {
  background: var(--xa-white);
  align-items: center;
  padding-left: calc(5px + 1vw);
  flex: 1;
  display: flex;
}
.footer-full-wrapper .footer-image-wrapper .image-wrapper img {
  max-height: 50px;
  width: auto;
}
.footer-full-wrapper .footer-button-wrapper {
  display: flex;
  align-items: center;
  padding-right: calc(10px + 1vw);
  gap: 15px;
}

.sm .footer-full-wrapper {
  left: 0 !important;
}
.sm .header {
  margin-left: 0 !important;
  width: calc(100vw - 5px - 1vw - 5px - 1vw) !important;
}
.sm .main-content {
  margin-left: 0 !important;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evently {
  justify-content: space-evenly;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--xa-secondary);
}

.dx-calendar-cell.dx-calendar-empty-cell {
  background: none;
}

.dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
  background-color: var(--xa-white);
}

dx-date-box:not(.dx-datebox-time) {
  pointer-events: none;
}
dx-date-box:not(.dx-datebox-time).dx-invalid, dx-date-box:not(.dx-datebox-time).ng-invalid {
  border: 1px solid red !important;
}
dx-date-box:not(.dx-datebox-time).dx-texteditor.dx-editor-outlined {
  border-radius: 8px;
  border: none;
}
dx-date-box:not(.dx-datebox-time).dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent;
}
dx-date-box:not(.dx-datebox-time) .dx-dropdowneditor-input-wrapper .dx-texteditor-input-container input {
  padding: 0px 12px;
  height: 38px;
}
dx-date-box:not(.dx-datebox-time) .dx-dropdowneditor-input-wrapper .dx-texteditor-buttons-container .dx-button-content .dx-dropdowneditor-icon::before {
  content: "";
  visibility: hidden;
}
dx-date-box:not(.dx-datebox-time) .dx-dropdowneditor-input-wrapper .dx-texteditor-buttons-container .dx-button-content .dx-dropdowneditor-icon {
  background-image: url("../img/date_picker.png");
  width: 20px;
  position: relative;
  right: 4px;
  pointer-events: visible;
}

dx-date-box input[type=datetime-local]::-webkit-calendar-picker-indicator {
  background: none !important;
}

.comment-modalbox .mat-mdc-dialog-surface {
  background-color: var(--xa-popup-light-gray);
  border-radius: 10px;
}

.SchedulePickupDate-modalbox {
  margin-top: -30vh;
}
.SchedulePickupDate-modalbox .mat-mdc-dialog-surface {
  background-color: var(--xa-popup-light-gray) !important;
  border-radius: 10px !important;
}

@media (max-height: 800px) {
  .SchedulePickupDate-modalbox {
    margin-top: -41vh;
  }
}
.cdk-drop-list-dragging .cdk-drag {
  gap: 15px !important;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  align-items: center;
}
.checkbox-wrapper .checkbox {
  display: flex;
  padding-left: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 30px !important;
  align-items: center;
}
.checkbox-wrapper .checkbox .span-label {
  font-size: 12px;
  text-transform: none;
  flex-shrink: 0;
}
.checkbox-wrapper .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-wrapper .checkbox .checkmark {
  height: 16px;
  width: 16px;
  border-radius: 3px;
  border: 1px solid var(--xa-gray);
  position: absolute;
  left: 0;
  right: 0;
}
.checkbox-wrapper .checkbox input:checked ~ .checkmark {
  background-color: #76D672;
  border: 1px solid #76D672;
}
.checkbox-wrapper .checkbox input:checked ~ .checkmark.primary {
  background-color: var(--xa-primary);
  border: 1px solid var(--xa-primary);
}
.checkbox-wrapper .checkbox input:checked ~ .checkmark.secondary {
  background-color: var(--xa-secondary);
  border: 1px solid var(--xa-secondary);
}
.checkbox-wrapper .checkbox input:checked ~ .checkmark.tertiary {
  background-color: var(--xa-tertiary);
  border: 1px solid var(--xa-tertiary);
}
.checkbox-wrapper .checkbox input:checked ~ .checkmark.white {
  background-color: var(--xa-white);
  border: 1px solid var(--xa-white);
}
.checkbox-wrapper .checkbox input:checked ~ .checkmark.red {
  background-color: var(--xa-red);
  border: 1px solid var(--xa-red);
}
.checkbox-wrapper .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-wrapper .checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox-wrapper .checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 3px;
  height: 7px;
  border: solid var(--xa-white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: start;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.profile-mat-menu + * .cdk-overlay-pane {
  margin-top: 8px !important;
}
.profile-mat-menu + * .mat-mdc-menu-content {
  padding: 0px !important;
  border-radius: 8px;
  min-width: 150px !important;
}
.profile-mat-menu + * .mat-mdc-menu-item {
  display: flex;
  align-items: center;
  height: 48px;
}
.profile-mat-menu + * .separator {
  height: 0.4px !important;
  background: var(--xa-gray);
  margin: 0px 16px;
  border: 0px !important;
}

.notification-mat-menu + * .cdk-overlay-pane {
  margin-top: 8px !important;
}
.notification-mat-menu + * .mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 360px;
}
.notification-mat-menu + * .mat-mdc-menu-content {
  padding: 16px !important;
  border-radius: 8px;
  min-width: 328px !important;
  height: 600px;
  box-shadow: none;
}
.notification-mat-menu + * .mat-mdc-menu-item {
  display: flex;
  align-items: center;
  height: 48px;
}
.notification-mat-menu + * .separator {
  height: 0.4px !important;
  background: var(--xa-gray);
  margin: 0px 16px;
  border: 0px !important;
}
.notification-mat-menu + * .notification-header {
  font-size: 20px;
  width: 100%;
}
.notification-mat-menu + * a {
  color: var(--xa-blue);
  cursor: pointer;
}
.notification-mat-menu + * .notification-details-panel {
  height: 450px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.notification-mat-menu + * .notification-detail {
  padding-top: 24px;
  width: 90%;
  font-family: "Inter";
  font-size: 12px;
  line-height: 19px;
  display: inline-block;
}
.notification-mat-menu + * .notification-button {
  width: auto;
  margin: auto;
  margin-bottom: 18px;
  font-family: "Inter";
  height: 32px;
}
.notification-mat-menu + * label {
  font-size: 12px;
  color: var(--xa-dark-gray);
  padding: 10px 0px;
  display: block;
  font-weight: 500;
}
.notification-mat-menu + * .notification-unread-dot {
  position: absolute;
  background: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  right: 6px;
  margin-top: -36px;
}

.operation-panel .mat-mdc-dialog-container .mdc-dialog__surface, .estimate-preview-panel .mat-mdc-dialog-container .mdc-dialog__surface, .case-submit-quote-id .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--xa-popup-light-gray);
}

.full-width-dialog {
  position: absolute !important;
  top: 0px;
}
.full-width-dialog .mat-mdc-dialog-container {
  padding: 20px;
}

.case-submit-success-panel .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--xa-white);
}

.payment-panel .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--xa-popup-light-gray);
}

.mat-expansion-no-padding .mat-expansion-panel-header,
.mat-expansion-no-padding .mat-expansion-panel-body {
  padding: 0px;
}

.mh-0 {
  margin-left: calc(-5px - 1vw);
  margin-right: calc(-5px - 1vw);
}

.mv-0 {
  margin-top: calc(-5px - 1vw);
  margin-bottom: calc(-5px - 1vw);
}

.mt-0 {
  margin-top: calc(-5px - 1vw);
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 100%;
}

.h-25 {
  height: 25%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 100%;
}

.w-25 {
  width: 25%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.fx-row {
  display: flex;
  flex-direction: row;
}

.fx-col {
  display: flex;
  flex-flow: column;
}

.fx-1 {
  flex: 1;
}

.fx-0 {
  flex: 0;
}

.d-flex {
  display: flex;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.position-relative {
  position: relative;
}

.color-xa-dark-red {
  color: var(--xa-dark-red);
}

.color-xa-repair-header {
  color: var(--xa-repair-header);
}

.col-xa-orange2 {
  color: var(--xa-orange2);
}

cd-timer {
  color: var(--xa-black-medium);
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.25px;
}

.mat-mdc-dialog-content {
  max-height: initial !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

:fullscreen side-menu,
:fullscreen .header-content,
:fullscreen .footer-full-wrapper,
:fullscreen .header {
  display: none !important;
}
:fullscreen .main-content {
  margin-left: 0 !important;
}
:fullscreen .addenda-container {
  padding: calc(5px + 1vw) !important;
  padding-bottom: 0 !important;
}
:fullscreen .lounge-list-container {
  height: 100vh !important;
}
:fullscreen .lounge-content .card {
  width: 98vw !important;
}
:fullscreen .workflow-container {
  height: 100vh !important;
}

.short-text {
  max-width: 20ch;
  word-break: break-all;
}

.mat-sort-header-arrow {
  color: var(--xa-white);
  margin: -2px !important;
}

@media (orientation: portrait) {
  mat-cell {
    word-break: break-word;
  }
  .mat-sort-header-arrow {
    margin: 0 !important;
  }
}
.claim-garage-auto-assignment {
  max-width: 600px !important;
  min-width: 500px;
}

.claim-garage-preference {
  max-width: 550px !important;
}

.xa-tab-content {
  background: var(--xa-tab-background);
  overflow: auto;
}

.hide {
  display: none !important;
}

@media (max-width: 420px) {
  .footer-full-wrapper .footer-image-wrapper .image-wrapper img {
    max-height: 40px !important;
  }
}
.form-generator .mat-expansion-panel-header {
  background: var(--xa-primary);
}
.form-generator .mat-expansion-panel-header .mat-expansion-panel-header-title,
.form-generator .mat-expansion-panel-header .mat-expansion-panel-header-description {
  color: var(--xa-white);
}
.form-generator .mat-expansion-panel-content {
  background: var(--xa-card-gray) !important;
}
.form-generator .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .form-generator .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .form-generator .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: var(--xa-secondary);
}
.form-generator .mat-expansion-panel-header.mat-expanded:focus,
.form-generator .mat-expansion-panel-header.mat-expanded:hover {
  background: var(--xa-secondary) !important;
}

.submit-missing-info .footer-full-wrapper {
  display: none !important;
}
.submit-missing-info chq-images-uploader .no-border {
  padding-bottom: 0px !important;
}
.submit-missing-info chq-review-section .header-div,
.submit-missing-info chq-review-section .button-wrapper {
  display: none;
}
.submit-missing-info chq-review-section .image-preview {
  border: none !important;
  background: transparent !important;
}
.submit-missing-info chq-review-section .image-preview .step-wrapper {
  background: var(--xa-white);
  margin-bottom: 18px;
}

/*button css */
button:not(.xa-button),
a.button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px 12px;
  gap: 7px;
  display: flex;
  flex-basis: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  font-family: "Inter", sans-serif;
}
button:not(.xa-button) label,
a.button label {
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}
button:not(.xa-button) mat-icon,
a.button mat-icon {
  height: 18px;
  width: 18px;
}

button:has(+ mat-icon svg) {
  line-height: 48px;
}

.button-dark {
  background-color: var(--xa-black);
  color: var(--xa-white);
}
.button-dark mat-icon {
  fill: var(--xa-white) !important;
}

.button-red {
  background-color: var(--xa-red);
  color: var(--xa-white);
}
.button-red mat-icon {
  fill: var(--xa-white) !important;
}

.button-outline-danger {
  background-color: var(--xa-white);
  border: 1px solid var(--xa-red);
}
.button-outline-danger label {
  color: var(--xa-red);
}
.button-outline-danger mat-icon {
  fill: var(--xa-red) !important;
}

.button-success {
  background-color: var(--xa-green);
  border: 1px solid var(--xa-green);
}
.button-success mat-icon {
  fill: var(--xa-black) !important;
}
.button-success label {
  color: var(--xa-black);
}

.button-primary {
  background-color: var(--xa-primary);
  border: 1px solid var(--xa-primary);
}
.button-primary mat-icon {
  fill: var(--xa-white) !important;
}
.button-primary label {
  color: var(--xa-white);
}

.button-secondary {
  background-color: var(--xa-secondary);
  border: 1px solid var(--xa-secondary);
}
.button-secondary mat-icon {
  fill: var(--xa-white) !important;
}
.button-secondary label {
  color: var(--xa-white);
}

.button-outline {
  background-color: transparent;
  border: 1px solid var(--xa-light-gray) !important;
}
.button-outline mat-icon {
  fill: var(--xa-black) !important;
}
.button-outline label {
  color: var(--xa-black);
}

.btn-disabled mat-icon {
  fill: var(--xa-white) !important;
}

.btn-disabled-outline {
  background-color: var(--xa-white);
  border: 1px solid var(--xa-light-gray);
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.custom-dialog,
.large-custom-dialog {
  width: 270px;
}
.custom-dialog .mat-mdc-dialog-container .mdc-dialog__surface,
.large-custom-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 14px !important;
  background: var(--xa-popup-light-gray) !important;
}
.custom-dialog .mat-dialog-container,
.large-custom-dialog .mat-dialog-container {
  border-radius: 14px;
  padding: 0px;
}
.custom-dialog .dialog-container,
.large-custom-dialog .dialog-container {
  padding: 16px 16px 11px !important;
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.36);
}
.custom-dialog .mdc-dialog__title::before,
.large-custom-dialog .mdc-dialog__title::before {
  height: 0 !important;
}
.custom-dialog .mat-mdc-dialog-title,
.large-custom-dialog .mat-mdc-dialog-title {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 22px !important;
  text-align: center;
  letter-spacing: -0.408px !important;
  color: #000000 !important;
  margin: 0 !important;
  padding: 0;
}
.custom-dialog .mat-mdc-dialog-content,
.large-custom-dialog .mat-mdc-dialog-content {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  text-align: center !important;
  letter-spacing: -0.078px !important;
  color: #000000 !important;
  padding: 0 16px !important;
  margin: 0 -16px !important;
  padding: 5px 11px 0 !important;
}
.custom-dialog .btn-container,
.large-custom-dialog .btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
}
.custom-dialog .btn-container button,
.large-custom-dialog .btn-container button {
  color: #007AFF;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0;
  height: 50px;
  flex: 1 100%;
  line-height: 30px;
  background: rgba(242, 242, 242, 0) !important;
}
.custom-dialog .btn-container .btn-cancel,
.large-custom-dialog .btn-container .btn-cancel {
  border-right: 0.5px solid rgba(60, 60, 67, 0.36);
}
.custom-dialog .btn-container .btn-no,
.large-custom-dialog .btn-container .btn-no {
  border-right: 0.5px solid rgba(60, 60, 67, 0.36);
}

.local-notification.mat-snack-bar-container {
  background: transparent;
  color: inherit;
  padding: 10px !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.local-notification .mdc-snackbar__surface {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(245, 245, 245, 0.6) !important;
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
  border-radius: 23px !important;
  padding: 14px !important;
  padding-right: 22px !important;
  width: 556px !important;
}
.local-notification .mat-mdc-snack-bar-label {
  padding: 0 !important;
}
.local-notification .toast-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.local-notification .toast-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.local-notification .toast-icon {
  padding-right: 10px;
}
.local-notification .toast-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.9px;
  font-feature-settings: "case" on;
  color: #000000;
}
.local-notification .toast-message {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #000000;
}
.local-notification .toast-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.local-notification .toast-time {
  font-size: 13px;
  line-height: 18px;
  opacity: 0.2;
  color: #000000 !important;
  font-weight: 500;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.dialog-with-header {
  width: 550px;
  background-color: #FFFFFF;
}
.dialog-with-header.large-width {
  width: 650px;
}
.dialog-with-header .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
  color: var(--xa-white);
}
.dialog-with-header .header .title {
  font-weight: 700;
  font-size: 16px;
}
.dialog-with-header .header .action button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  background: transparent;
  height: 25px;
}
.dialog-with-header .header .action button .mat-icon {
  height: 22px;
  width: 22px;
}
.dialog-with-header .form-container .input-wrapper .input-label {
  margin-bottom: 10px;
}
.dialog-with-header .form-container .input-wrapper .checkbox-wrapper {
  flex-wrap: wrap;
}
.dialog-with-header .form-container .input-wrapper .checkbox-wrapper label {
  width: 74px;
}
.dialog-with-header .form-container hr {
  margin: 20px 0;
}
.dialog-with-header .form-container mat-slider {
  width: 100%;
}
.dialog-with-header .form-container .repair-type label {
  width: 133px !important;
}
.dialog-with-header .section-header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.dialog-with-header .section-header.transparent {
  background: transparent;
  border: none;
}
.dialog-with-header .section-header .section-title {
  height: 14px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--xa-black-medium);
}
.dialog-with-header .section-header .section-title mat-icon {
  height: 15px;
  width: 15px;
}

.large-custom-dialog {
  width: 500px;
}
.large-custom-dialog .mat-mdc-dialog-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}
.large-custom-dialog .mat-mdc-dialog-title::before {
  display: none;
}
.large-custom-dialog .mat-mdc-dialog-title .close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.large-custom-dialog .mat-mdc-dialog-title .close-icon mat-icon {
  height: 16px;
  width: 16px;
}
.large-custom-dialog .mat-mdc-dialog-content {
  text-align: left !important;
  padding: 20px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 0.5cm;
    margin-top: 1.5cm;
  }
  .do-not-print {
    display: none;
  }
}
a.xa-button,
button.xa-button {
  display: inline-flex;
  padding: 14px 18px;
  align-items: center;
  justify-content: center;
  color: var(--xa-black);
  flex-direction: row;
  gap: 10px;
  position: relative;
  flex: auto;
  font-family: "Inter", sans-serif;
}
a.xa-button mat-icon,
button.xa-button mat-icon {
  height: 18px;
  width: 18px;
}
a.xa-button::before,
button.xa-button::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  content: "";
  background-color: var(--xa-black);
  opacity: 0;
  border-radius: inherit;
}
a.xa-button:hover::before, a.xa-button:active::before,
button.xa-button:hover::before,
button.xa-button:active::before {
  opacity: 0.04;
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
}
@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 0.04;
  }
}
@-moz-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 0.04;
  }
}
@-o-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 0.04;
  }
}
@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 0.04;
  }
}
a.xa-button[color=primary],
button.xa-button[color=primary] {
  background-color: var(--xa-primary);
  border: 1px solid var(--xa-primary);
  color: var(--xa-white);
}
a.xa-button[color=primary] mat-icon,
button.xa-button[color=primary] mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[color=secondary],
button.xa-button[color=secondary] {
  background-color: var(--xa-secondary);
  border: 1px solid var(--xa-secondary);
  color: var(--xa-white);
}
a.xa-button[color=secondary] mat-icon,
button.xa-button[color=secondary] mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[color=tertiary],
button.xa-button[color=tertiary] {
  background-color: var(--xa-tertiary);
  border: 1px solid var(--xa-tertiary);
}
a.xa-button[color=tertiary] mat-icon,
button.xa-button[color=tertiary] mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[color=success],
button.xa-button[color=success] {
  background-color: var(--xa-green);
  border: 1px solid var(--xa-green);
}
a.xa-button[color=success] mat-icon,
button.xa-button[color=success] mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[color=danger],
button.xa-button[color=danger] {
  background-color: var(--xa-red);
  color: var(--xa-white);
  border: 1px solid var(--xa-red);
}
a.xa-button[color=danger] mat-icon,
button.xa-button[color=danger] mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[color=dark],
button.xa-button[color=dark] {
  background-color: var(--xa-black);
  color: var(--xa-white);
  border: 1px solid var(--xa-black);
}
a.xa-button[color=dark] mat-icon,
button.xa-button[color=dark] mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[color=gray],
button.xa-button[color=gray] {
  color: var(--xa-white);
  background-color: var(--xa-dark-gray);
}
a.xa-button:not([appearancetype=outline]):not([appearancetype=basic]),
button.xa-button:not([appearancetype=outline]):not([appearancetype=basic]) {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
a.xa-button:not([appearancetype=outline]):not([appearancetype=basic]):hover,
button.xa-button:not([appearancetype=outline]):not([appearancetype=basic]):hover {
  box-shadow: var(--mdc-protected-button-hover-container-elevation, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
}
a.xa-button[appearancetype=radius], a.xa-button[appearancetype=outline-radius],
button.xa-button[appearancetype=radius],
button.xa-button[appearancetype=outline-radius] {
  border-radius: 36px;
}
a.xa-button[appearancetype=outline-zero],
button.xa-button[appearancetype=outline-zero] {
  min-width: 0 !important;
  background-color: transparent;
  border: 1px solid var(--xa-light-gray);
  color: var(--xa-black) !important;
}
a.xa-button[appearancetype=outline-zero] mat-icon,
button.xa-button[appearancetype=outline-zero] mat-icon {
  fill: var(--xa-black) !important;
}
a.xa-button[appearancetype=outline-zero][color=danger],
button.xa-button[appearancetype=outline-zero][color=danger] {
  border: 1px solid var(--xa-red);
  color: var(--xa-red) !important;
}
a.xa-button[appearancetype=outline-zero][color=danger] .mat-icon,
button.xa-button[appearancetype=outline-zero][color=danger] .mat-icon {
  fill: var(--xa-red) !important;
}
a.xa-button[appearancetype=outline], a.xa-button[appearancetype=outline-radius],
button.xa-button[appearancetype=outline],
button.xa-button[appearancetype=outline-radius] {
  background-color: transparent;
  border: 1px solid var(--xa-light-gray);
  color: var(--xa-black) !important;
}
a.xa-button[appearancetype=outline] mat-icon, a.xa-button[appearancetype=outline-radius] mat-icon,
button.xa-button[appearancetype=outline] mat-icon,
button.xa-button[appearancetype=outline-radius] mat-icon {
  fill: var(--xa-black) !important;
}
a.xa-button[appearancetype=basic],
button.xa-button[appearancetype=basic] {
  background-color: transparent;
  border: none;
  color: var(--xa-black) !important;
}
a.xa-button[appearancetype=basic] mat-icon,
button.xa-button[appearancetype=basic] mat-icon {
  fill: var(--xa-black) !important;
}
a.xa-button[appearancetype=basic][color=danger],
button.xa-button[appearancetype=basic][color=danger] {
  color: var(--xa-red) !important;
}
a.xa-button[appearancetype=basic][color=danger] mat-icon,
button.xa-button[appearancetype=basic][color=danger] mat-icon {
  fill: var(--xa-red) !important;
}
a.xa-button[appearancetype=outline][color=primary],
button.xa-button[appearancetype=outline][color=primary] {
  border: 1px solid var(--xa-primary);
  color: var(--xa-primary) !important;
}
a.xa-button[appearancetype=outline][color=primary] mat-icon,
button.xa-button[appearancetype=outline][color=primary] mat-icon {
  fill: var(--xa-primary) !important;
}
a.xa-button[appearancetype=outline][color=secondary],
button.xa-button[appearancetype=outline][color=secondary] {
  border: 1px solid var(--xa-secondary);
  color: var(--xa-secondary) !important;
}
a.xa-button[appearancetype=outline][color=secondary] mat-icon,
button.xa-button[appearancetype=outline][color=secondary] mat-icon {
  fill: var(--xa-secondary) !important;
}
a.xa-button[appearancetype=outline][color=tertiary],
button.xa-button[appearancetype=outline][color=tertiary] {
  border: 1px solid var(--xa-tertiary);
  color: var(--xa-tertiary) !important;
}
a.xa-button[appearancetype=outline][color=tertiary] mat-icon,
button.xa-button[appearancetype=outline][color=tertiary] mat-icon {
  fill: var(--xa-tertiary) !important;
}
a.xa-button[appearancetype=outline][color=success],
button.xa-button[appearancetype=outline][color=success] {
  border: 1px solid var(--xa-green);
  fill: var(--xa-green) !important;
  color: var(--xa-green) !important;
}
a.xa-button[appearancetype=outline][color=success] mat-icon,
button.xa-button[appearancetype=outline][color=success] mat-icon {
  fill: var(--xa-green) !important;
}
a.xa-button[appearancetype=outline][color=danger],
button.xa-button[appearancetype=outline][color=danger] {
  border: 1px solid var(--xa-red);
  fill: var(--xa-red) !important;
  color: var(--xa-red) !important;
}
a.xa-button[appearancetype=outline][color=danger] mat-icon,
button.xa-button[appearancetype=outline][color=danger] mat-icon {
  fill: var(--xa-red) !important;
}
a.xa-button[appearancetype=outline][color=dark],
button.xa-button[appearancetype=outline][color=dark] {
  border: 1px solid var(--xa-black);
  color: var(--xa-black) !important;
}
a.xa-button[appearancetype=outline][color=dark] mat-icon,
button.xa-button[appearancetype=outline][color=dark] mat-icon {
  fill: var(--xa-black) !important;
}
a.xa-button[appearancetype=outline][color=gray],
button.xa-button[appearancetype=outline][color=gray] {
  border: 1px solid var(--xa-light-gray);
  color: var(--xa-black-medium) !important;
}
a.xa-button[appearancetype=outline][color=gray] mat-icon,
button.xa-button[appearancetype=outline][color=gray] mat-icon {
  fill: var(--xa-black-medium) !important;
}
a.xa-button[appearancetype=primary][color=danger],
button.xa-button[appearancetype=primary][color=danger] {
  color: var(--xa-white);
}
a.xa-button[appearancetype=primary][color=danger] mat-icon,
button.xa-button[appearancetype=primary][color=danger] mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[appearancetype=chip],
button.xa-button[appearancetype=chip] {
  border-radius: 10px !important;
  height: 18px !important;
  padding: 3px 8px 3px 8px !important;
  font-size: 8px !important;
  line-height: 12px !important;
  font-weight: 500 !important;
}
a.xa-button[appearancetype=chip][color=grey],
button.xa-button[appearancetype=chip][color=grey] {
  color: var(--xa-black);
  background-color: var(--xa-draft);
}
a.xa-button[appearancetype=chip][color=green],
button.xa-button[appearancetype=chip][color=green] {
  color: var(--xa-black);
  background-color: var(--xa-approve);
}
a.xa-button[appearancetype=chip][color=red],
button.xa-button[appearancetype=chip][color=red] {
  color: var(--xa-white);
  background-color: var(--xa-noshow);
}
a.xa-button[appearancetype=chip][color=bluish-grey],
button.xa-button[appearancetype=chip][color=bluish-grey] {
  color: var(--xa-white);
  background-color: var(--xa-bluesh-grey);
}
a.xa-button[appearancetype=chip][color=teal],
button.xa-button[appearancetype=chip][color=teal] {
  color: var(--xa-black);
  background-color: var(--xa-upload-document);
}
a.xa-button[appearancetype=chip][color=brown],
button.xa-button[appearancetype=chip][color=brown] {
  color: var(--xa-white);
  background-color: var(--xa-upload-photo);
}
a.xa-button[appearancetype=chip][color=yellow],
button.xa-button[appearancetype=chip][color=yellow] {
  color: var(--xa-black);
  background-color: var(--xa-repair-estimate);
}
a.xa-button[appearancetype=chip][color=purple],
button.xa-button[appearancetype=chip][color=purple] {
  color: var(--xa-white);
  background-color: var(--xa-ai-analysis);
}
a.xa-button[appearancetype=chip][color=blue],
button.xa-button[appearancetype=chip][color=blue] {
  color: var(--xa-white);
  background-color: var(--xa-blue);
}
a.xa-button[appearancetype=fab],
button.xa-button[appearancetype=fab] {
  border-radius: 100%;
  background-color: var(--xa-black);
  border: 1px solid var(--xa-black);
}
a.xa-button[appearancetype=fab] mat-icon,
button.xa-button[appearancetype=fab] mat-icon {
  color: var(--xa-white);
  fill: var(--xa-white) !important;
}
a.xa-button[appearancetype=fab][color=primary],
button.xa-button[appearancetype=fab][color=primary] {
  background-color: var(--xa-primary);
  border: 1px solid var(--xa-primary);
}
a.xa-button[appearancetype=fab][color=secondary],
button.xa-button[appearancetype=fab][color=secondary] {
  background-color: var(--xa-secondary);
  border: 1px solid var(--xa-secondary);
}
a.xa-button[appearancetype=fab][color=tertiary],
button.xa-button[appearancetype=fab][color=tertiary] {
  background-color: var(--xa-tertiary);
  border: 1px solid var(--xa-tertiary);
}
a.xa-button[appearancetype=fab][color=success],
button.xa-button[appearancetype=fab][color=success] {
  background-color: var(--xa-green);
  border: 1px solid var(--xa-green);
}
a.xa-button[appearancetype=fab][color=danger],
button.xa-button[appearancetype=fab][color=danger] {
  background-color: var(--xa-red);
  border: 1px solid var(--xa-red);
}
a.xa-button[appearancetype=fab][color=dark],
button.xa-button[appearancetype=fab][color=dark] {
  background-color: var(--xa-black);
  border: 1px solid var(--xa-black);
}
a.xa-button[appearancetype=mini-fab],
button.xa-button[appearancetype=mini-fab] {
  border-radius: 100%;
  background-color: var(--xa-black);
  border: 1px solid var(--xa-black);
  height: 30px !important;
  width: 30px !important;
  padding: 0px;
}
a.xa-button[appearancetype=mini-fab] mat-icon,
button.xa-button[appearancetype=mini-fab] mat-icon {
  height: 16px !important;
  width: 16px !important;
  color: var(--xa-white);
  fill: var(--xa-white) !important;
}
a.xa-button[appearancetype=outline-fab],
button.xa-button[appearancetype=outline-fab] {
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid var(--xa-light-gray);
  padding: 0px;
  min-width: 40px;
}
a.xa-button[appearancetype=outline-fab] mat-icon,
button.xa-button[appearancetype=outline-fab] mat-icon {
  color: var(--xa-black);
  fill: var(--xa-black) !important;
}
a.xa-button[appearancetype=fab-basic],
button.xa-button[appearancetype=fab-basic] {
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0px;
  min-width: 40px;
}
a.xa-button[appearancetype=fab-basic] mat-icon,
button.xa-button[appearancetype=fab-basic] mat-icon {
  color: var(--xa-black);
  fill: var(--xa-black) !important;
}
a.xa-button[appearancetype=mini-fab-outline],
button.xa-button[appearancetype=mini-fab-outline] {
  border-radius: 100%;
  background-color: transparent;
  border: 2px dashed var(--xa-light-gray);
  height: 40px !important;
  width: 40px !important;
  padding: 0px;
}
a.xa-button[appearancetype=mini-fab-outline] mat-icon,
button.xa-button[appearancetype=mini-fab-outline] mat-icon {
  height: 20px !important;
  width: 20px !important;
  color: var(--xa-light-gray) !important;
  fill: var(--xa-light-gray) !important;
}
a.xa-button[size=xs]:not([appearancetype*=fab]),
button.xa-button[size=xs]:not([appearancetype*=fab]) {
  height: 22px;
  min-width: 74px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
}
a.xa-button[size=xs][appearancetype*=fab],
button.xa-button[size=xs][appearancetype*=fab] {
  height: 18px;
  width: 18px;
  font-size: 10px;
  line-height: 12px;
  padding: 4px;
}
a.xa-button[size=sm][appearancetype=fab], a.xa-button[size=sm][appearancetype=outline-fab], a.xa-button[size=sm][appearancetype=fab-basic],
button.xa-button[size=sm][appearancetype=fab],
button.xa-button[size=sm][appearancetype=outline-fab],
button.xa-button[size=sm][appearancetype=fab-basic] {
  height: 32px;
  width: 32px;
  padding: 8px;
  min-width: unset;
}
a.xa-button[size=lg][appearancetype=mini-fab],
button.xa-button[size=lg][appearancetype=mini-fab] {
  height: 48px !important;
  width: 48px !important;
  min-width: unset;
}
a.xa-button[size=lg][appearancetype=mini-fab] mat-icon,
button.xa-button[size=lg][appearancetype=mini-fab] mat-icon {
  height: 16px !important;
  width: 16px !important;
}
a.xa-button[size=sm]:not([appearancetype*=fab]),
button.xa-button[size=sm]:not([appearancetype*=fab]) {
  height: 30px;
  min-width: 94px;
  font-size: 14px;
  line-height: 18px;
  padding: 6px 12px 6px 12px;
}
a.xa-button[size=md][appearancetype=fab], a.xa-button[size=md][appearancetype=outline-fab], a.xa-button[size=md][appearancetype=fab-basic],
button.xa-button[size=md][appearancetype=fab],
button.xa-button[size=md][appearancetype=outline-fab],
button.xa-button[size=md][appearancetype=fab-basic] {
  height: 44px;
  width: 44px;
  min-width: unset;
  padding: 12px;
}
a.xa-button[size=md]:not([appearancetype*=fab]),
button.xa-button[size=md]:not([appearancetype*=fab]) {
  height: 40px;
  min-width: 100px;
  font-size: 14px;
  line-height: 14px;
  padding: 10px 12px 10px 12px;
}
a.xa-button[size=wide]:not([appearancetype*=fab]),
button.xa-button[size=wide]:not([appearancetype*=fab]) {
  height: 40px;
  min-width: 175px;
  font-size: 14px;
  line-height: 14px;
  padding: 10px 12px 10px 12px;
}
a.xa-button[size=lg][appearancetype=fab], a.xa-button[size=lg][appearancetype=outline-fab], a.xa-button[size=lg][appearancetype=fab-basic],
button.xa-button[size=lg][appearancetype=fab],
button.xa-button[size=lg][appearancetype=outline-fab],
button.xa-button[size=lg][appearancetype=fab-basic] {
  height: 56px;
  width: 56px;
  min-width: unset;
  padding: 16;
}
a.xa-button[size=lg]:not([appearancetype*=fab]),
button.xa-button[size=lg]:not([appearancetype*=fab]) {
  height: 48px;
  min-width: 112px;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 18px 14px 18px;
}
a.xa-button:disabled,
button.xa-button:disabled {
  opacity: 0.4;
  pointer-events: none;
}
a.xa-button:not([appearancetype=outline]):not([appearancetype=basic]):disabled,
button.xa-button:not([appearancetype=outline]):not([appearancetype=basic]):disabled {
  border: 0px;
  pointer-events: none;
  opacity: 0.4;
}
a.xa-button:not([appearancetype=outline]):not([appearancetype=basic]):disabled mat-icon,
button.xa-button:not([appearancetype=outline]):not([appearancetype=basic]):disabled mat-icon {
  fill: var(--xa-white) !important;
}
a.xa-button[borderType=modern],
button.xa-button[borderType=modern] {
  border-radius: 20px;
}